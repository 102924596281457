export const SALARY_TYPE = {
  MONTHLY: {
    ID: 'MONTHLY',
    LABEL: '월급',
  },
  DAILY: {
    ID: 'DAILY',
    LABEL: '일급',
  },
  HOURLY: {
    ID: 'HOURLY',
    LABEL: '시급',
  },
  PER_CASE: {
    ID: 'PER_CASE',
    LABEL: '건당',
  },
  UNKNOWN: {
    ID: 'UNKNOWN',
    LABEL: '',
  },
} as const

export type SalaryTypeIdsType =
  (typeof SALARY_TYPE)[keyof typeof SALARY_TYPE]['ID']

type WorkDaysType = {
  [key: string]: { ID: string; LABEL: string; INDEX: number }
}

export const WORK_DAYS: WorkDaysType = {
  SUN: {
    ID: 'SUN',
    LABEL: '일',
    INDEX: 6,
  },
  MON: {
    ID: 'MON',
    LABEL: '월',
    INDEX: 0,
  },
  TUE: {
    ID: 'TUE',
    LABEL: '화',
    INDEX: 1,
  },
  WED: {
    ID: 'WED',
    LABEL: '수',
    INDEX: 2,
  },
  THU: {
    ID: 'THU',
    LABEL: '목',
    INDEX: 3,
  },
  FRI: {
    ID: 'FRI',
    LABEL: '금',
    INDEX: 4,
  },
  SAT: {
    ID: 'SAT',
    LABEL: '토',
    INDEX: 5,
  },
  UNKNOWN: {
    ID: 'UNKNOWN',
    LABEL: '알수없음',
    INDEX: -1,
  },
} as const
