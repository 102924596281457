import {
  ReactNode,
  useState,
  useRef,
  useMemo,
  createContext,
  useContext,
  useLayoutEffect,
} from 'react'
import {
  ExperimentUserInfo,
  getServiceSearchV2,
} from '../../services/search_v2'
import { AppType, useFoundation, UserType } from './FoundationProvider'
import { experimentStateMapper } from '../../experiment/utils/experimentStateMapper'
import { wrapForSuspense } from '../utils/wrapForSuspense'
import { AdInitializeInfo } from '../../services/search_v2/AdInitialize'

const ExperimentContext = createContext<ExperimentUserInfo | null>(null)
const ImpressionContext = createContext<impressionState | null>(null)
const AdInitializeContext = createContext<AdInitializeInfo | null>(null)

function makeInitializeResource(resource: { user: UserType; app: AppType }) {
  const promise = (async () => {
    try {
      const serviceSearchV2 = getServiceSearchV2({
        app: resource.app,
        authToken: resource.user.authToken,
      })

      return await serviceSearchV2.getAppInitialize()
    } catch (e) {
      return null
    }
  })()

  return wrapForSuspense(promise)
}

const InitializeResourceProvider = (props: {
  resource: ReturnType<typeof makeInitializeResource>
  children: ReactNode
}) => {
  const appInitializeData = props.resource.read() ?? null

  const impressionMemories = useMemo(() => {
    if (!appInitializeData?.impression_info) {
      return {}
    }

    return {
      coolTime: appInitializeData.impression_info?.cool_time_second * 1000,
      exposureTime: appInitializeData.impression_info?.exposure_duration * 1000,
      threshold: appInitializeData.impression_info?.impression_ratio,
    }
  }, [appInitializeData?.impression_info])

  const userInfoMemories = useMemo(() => {
    if (!appInitializeData?.experiment_user_info) {
      return {
        experiment_segments: [],
        header_segment_key: '',
        header_segment_value: '',
      }
    }

    return appInitializeData?.experiment_user_info
  }, [appInitializeData?.experiment_user_info])

  const adInfoMemories = useMemo(() => {
    if (!appInitializeData?.ad) {
      return {
        dsp_logging_sample_rate: 0,
      }
    }
    return appInitializeData.ad
  }, [appInitializeData?.ad])

  return (
    <ExperimentContextProvider value={userInfoMemories}>
      <ImpressionContextProvider value={impressionMemories}>
        <AdInitializeContextProvider value={adInfoMemories}>
          {props.children}
        </AdInitializeContextProvider>
      </ImpressionContextProvider>
    </ExperimentContextProvider>
  )
}

const AdInitializeContextProvider = (props: {
  children: ReactNode
  value: AdInitializeInfo
}) => {
  return (
    <AdInitializeContext.Provider value={props.value}>
      {props.children}
    </AdInitializeContext.Provider>
  )
}

const ExperimentContextProvider = (props: {
  children: ReactNode
  value: ExperimentUserInfo
}) => {
  return (
    <ExperimentContext.Provider value={props.value}>
      {props.children}
    </ExperimentContext.Provider>
  )
}

type impressionState = {
  coolTime?: number
  exposureTime?: number
  threshold?: number
}

interface ImpressionContextProviderProps {
  children: ReactNode
  value: impressionState
}
const ImpressionContextProvider = (props: ImpressionContextProviderProps) => {
  return (
    <ImpressionContext.Provider value={props.value}>
      {props.children}
    </ImpressionContext.Provider>
  )
}

export const AppInitializeProvider = (props: { children: ReactNode }) => {
  const { app, user } = useFoundation()
  const [resource, setResource] = useState<ReturnType<
    typeof makeInitializeResource
  > | null>(null)
  const isMountOnce = useRef<boolean>(false)

  useLayoutEffect(() => {
    if (isMountOnce.current) {
      return
    }

    setResource(makeInitializeResource({ app, user }))

    return () => {
      if (!app || !user) {
        return
      }

      isMountOnce.current = true
    }
  }, [app, user])

  // TODO: Loading
  if (!resource) {
    return null
  }

  return (
    <InitializeResourceProvider resource={resource}>
      {props.children}
    </InitializeResourceProvider>
  )
}

export const useExperimentContext = () => {
  const state = useContext(ExperimentContext)

  if (!state) {
    throw new Error(
      'useExperimentContext must be used within a ExperimentContextProvider'
    )
  }

  return experimentStateMapper(state)
}

export const useImpressionContext = () => {
  const state = useContext(ImpressionContext)
  return {
    coolTime: state?.coolTime,
    exposureTime: state?.exposureTime,
    threshold: state?.threshold,
  }
}

export const useAdInitializeContext = () => {
  const state = useContext(AdInitializeContext)
  return {
    DSPLoggingSampleRate: state?.dsp_logging_sample_rate || 0,
  }
}
