import {
  SEARCH_LOCAL_STORAGE_STATE_KEY,
  SEARCH_LOCAL_STORAGE_REFERRER_BRIDGE_KEY,
  SEARCH_LOCAL_STORAGE_FOUNDATION_OVERRIDE_KEY,
  SEARCH_COOKIE_STORAGE_FOUNDATION_OVERRIDE_KEY,
  SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS_KEY,
  SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS,
} from '../constants/common'
import {
  DEFAULT_APP,
  DEFAULT_REGION,
  DEFAULT_USER,
} from '../../bridge/constants'

export interface PersistedRecentSearchHistory {
  query: string
  eventTime: string
}

interface PersistedState {
  hoianSearchHistories: string[]
  recentSearchHistories: PersistedRecentSearchHistory[]
  hideClosedArticles: boolean
  showKeywordNotificationAnnouncement: boolean
  showKeywordNotificationActionSheet: boolean
  showExperimentalKeywordNotificationAnnouncement: boolean
}

export function setPersistedState(
  state: Partial<PersistedState>
): PersistedState {
  const prevState = getPersistedState()
  const nextState = {
    ...prevState,
    ...state,
  }

  // 브라우저 로컬스토리지
  localStorage.setItem(
    SEARCH_LOCAL_STORAGE_STATE_KEY,
    JSON.stringify(nextState)
  )

  return nextState
}

export function getPersistedState(): PersistedState {
  const state = localStorage.getItem(SEARCH_LOCAL_STORAGE_STATE_KEY)
  return {
    ...getInitialPersistedState(),
    ...(state ? (JSON.parse(state) as PersistedState) : null),
  }
}

function getInitialPersistedState(): PersistedState {
  return {
    hoianSearchHistories: [],
    recentSearchHistories: [],
    hideClosedArticles: false,
    showKeywordNotificationAnnouncement: true,
    showKeywordNotificationActionSheet: true,
    showExperimentalKeywordNotificationAnnouncement: true,
  }
}

/**
 * 검색 Referrer Bridge
 * 검색 '디스커버 페이지'와 '검색 결과 페이지'가 네이티브 앱을 통해 분리되어 각각의 별도의 HTML로 운용되고 있는 상태 (딥링크로 호출)
 * 두 HTML 사이의 데이터를 넘겨받을 수 있게 임시 저장
 * @param state
 */
interface ReferrerBridgePersistedState {
  searchFunnelId: string
}

const getInitialReferrerBridgePersistedState =
  (): ReferrerBridgePersistedState => {
    return {
      searchFunnelId: '',
    }
  }

export const setReferrerBridgePersistedState = (
  state: Partial<ReferrerBridgePersistedState>
): ReferrerBridgePersistedState => {
  const prevState = getReferrerBridgePersistedState()
  const nextState = {
    ...prevState,
    ...state,
  }

  localStorage.setItem(
    SEARCH_LOCAL_STORAGE_REFERRER_BRIDGE_KEY,
    JSON.stringify(nextState)
  )

  return nextState
}

export const getReferrerBridgePersistedState =
  (): ReferrerBridgePersistedState => {
    const state = localStorage.getItem(SEARCH_LOCAL_STORAGE_REFERRER_BRIDGE_KEY)
    return {
      ...getInitialReferrerBridgePersistedState(),
      ...(state ? (JSON.parse(state) as ReferrerBridgePersistedState) : null),
    }
  }

/**
 * Staging과 프로덕션 테스트에 사용되는 state, foundation override 용도
 * @param state
 */
interface FoundationOverridePersistedState {
  region: typeof DEFAULT_REGION | null
  user: typeof DEFAULT_USER | null
  app: typeof DEFAULT_APP | null
}

export const setFoundationOverridePersistedState = (
  state: Partial<FoundationOverridePersistedState>
): FoundationOverridePersistedState => {
  const prevState = getFoundationOverridePersistedState()
  const nextState = {
    ...prevState,
    ...state,
  }

  localStorage.setItem(
    SEARCH_LOCAL_STORAGE_FOUNDATION_OVERRIDE_KEY,
    JSON.stringify(nextState)
  )

  return nextState
}

const getCookie = (name: string) => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\+^])/g, '\\$1') + '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

/**
 * 브릿지에서 가져오는 foundation을 override하기 위한 용도
 * Staging, 어드민 preview에서 사용
 * 실제 서비스 영향 X
 */
const getFoundationOverrideInStore = () => {
  const state = localStorage.getItem(
    SEARCH_LOCAL_STORAGE_FOUNDATION_OVERRIDE_KEY
  )

  if (!state) {
    return getCookie(SEARCH_COOKIE_STORAGE_FOUNDATION_OVERRIDE_KEY)
  }

  return state
}

export const getFoundationOverridePersistedState =
  (): FoundationOverridePersistedState => {
    const state = getFoundationOverrideInStore()

    const initial = (): FoundationOverridePersistedState => {
      return {
        region: null,
        user: null,
        app: null,
      }
    }

    try {
      return {
        ...initial(),
        ...(state
          ? (JSON.parse(state) as FoundationOverridePersistedState)
          : null),
      }
    } catch (e) {
      return initial()
    }
  }

/**
 * 최근 검색어 마이그레이션 상태를 tracking하기 위한 status 정보
 */
export const getRecentSearchMigrationStatusInStore = () => {
  const state = localStorage.getItem(
    SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS_KEY
  )

  switch (state) {
    case 'WITH_SERVER_RESPONSE':
      return SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS.WITH_SERVER_RESPONSE
    case 'DONE':
      return SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS.DONE
    case 'IN_MIGRATION':
    default:
      return SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS.IN_MIGRATION
  }
}

export const setRecentSearchMigrationStatusToStore = (
  status: (typeof SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS)[keyof typeof SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS]
) => {
  localStorage.setItem(
    SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS_KEY,
    status
  )
}
