import to from 'await-to-js'
import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import camelcaseKeys from 'camelcase-keys'

import { HOIAN_ENDPOINT, RETRY_COUNT } from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import { DocumentRegionRange } from './RegionRange'
import { AppType } from '../../_app/context/FoundationProvider'
import { SearchExperimentReferrerType } from '../../referrer/types'
import { addExperimentXSearchHeader } from '../../_app/utils/addCustomHeader'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
} from '../../plantae/plugins'

let hoian: ServiceHoian | null = null

export function getServiceHoian({
  app,
  authToken,
}: {
  app: AppType
  authToken: string
}) {
  if (hoian) {
    return hoian
  }

  return (hoian = new ServiceHoian({
    baseUrl: HOIAN_ENDPOINT,
    app,
    authToken,
  }))
}

export class ServiceHoian {
  private client: AxiosInstance

  constructor({
    baseUrl,
    app,
    authToken,
  }: {
    baseUrl: string
    app: AppType
    authToken: string
  }) {
    this.client = axios.create({
      baseURL: baseUrl,
    })

    daangnAxiosInterceptors({
      client: this.client,
      plugins: [
        plantaeAuthPlugin({ fallbackAuthToken: authToken }),
        plantaeRequestIdPlugin(),
        plantaeKarrotSessionIdPlugin({ app }),
        plantaeUserAgentPlugin({ userAgent: app.userAgent }),
        plantaeCommonHeadersPlugin(),
      ],
    })

    this.client.interceptors.response.use((response) => {
      response.data = camelcaseKeys(response.data, { deep: true })
      return response
    })
    axiosRetry(this.client, {
      retries: RETRY_COUNT,
      retryDelay: () => 0,
      retryCondition: () => true,
    })
  }

  /**
   * 중고거래 필터, 지역 범위
   * @param regionId
   */
  async getRegionRanges({
    regionId,
    referrer,
  }: {
    regionId: number
    referrer: {
      experiment: SearchExperimentReferrerType
    }
  }) {
    const [error, resp] = await to(
      this.client.get<{
        regionRanges: DocumentRegionRange[]
      }>(`/api/v23/regions/${regionId}/region_ranges.json`, {
        params: {
          range_type: 'view',
        },
        headers: {
          ...addExperimentXSearchHeader(referrer.experiment),
        },
      })
    )

    if (error || !resp?.data) {
      captureException(error)
      return null
    }

    return resp.data.regionRanges
  }
}
