import { createAxiosInterceptors, type Plugin } from 'plantae'
import retryPlugin from '@plantae/plugin-retry'
import { AxiosInstance } from 'axios'

const daangnAxiosInterceptors = ({
  client,
  plugins,
  retryOptions,
}: {
  client: AxiosInstance
  plugins?: Array<Plugin>
  retryOptions?: { retryCount: number; retryDelay: number }
}) => {
  const plantaePlugins = [...(plugins ?? [])]

  if (retryOptions) {
    plantaePlugins.push(
      retryPlugin({
        limit: retryOptions.retryCount,
        maxRetryAfter: retryOptions.retryDelay,
      })
    )
  }

  const { request, response } = createAxiosInterceptors({
    client,
    plugins: [...plantaePlugins],
  })

  /**
   * Plantae 내부에서 request body를 변형하는 로직이 포함되어있음.
   * 이로 인해, ArrayBuffer 데이터가 Binary로 덮어 씌워짐.
   * 관련 논의 쓰레드: https://daangn.slack.com/archives/CGDR2PPM2/p1695026258694429?thread_ts=1694999960.579649&cid=CGDR2PPM2
   * 관련 코드: https://github.com/daangn/plantae/blob/c861358118a445a7feee29f6be2501895564921d/packages/plantae/src/axios/createAxiosInterceptors.ts#L78
   */
  client.interceptors.request.use(request.onFulfilled, request.onRejected)
  client.interceptors.response.use(response.onFulfilled, response.onRejected)
}
export default daangnAxiosInterceptors
