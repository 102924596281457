import '@seed-design/stylesheet/global.css'
import '@daangn/sprout-components-snackbar/index.css'
import '@daangn/sprout-components-help-bubble/index.css'
;(function () {
  const el = document.documentElement
  el.dataset.seed = ''

  var prefersLight = window.matchMedia('(prefers-color-scheme: light)')
  var prefersDark = window.matchMedia('(prefers-color-scheme: dark)')

  if (prefersLight.matches) {
    if ('addEventListener' in prefersLight) {
      prefersLight.addEventListener('change', apply)
    } else if ('addListener' in prefersLight) {
      // @ts-ignore
      prefersLight.addListener(apply)
    }
  } else if (prefersDark.matches) {
    if ('addEventListener' in prefersDark) {
      prefersDark.addEventListener('change', apply)
    } else if ('addListener' in prefersDark) {
      // @ts-ignore
      prefersDark.addListener(apply)
    }
  }

  function isAndroid() {
    return Boolean(window.AndroidFunction)
  }

  function apply() {
    el.dataset.seedScaleColor = prefersDark.matches ? 'dark' : 'light'
    el.dataset.seedScaleLetterSpacing = isAndroid() ? 'android' : 'ios' // Web이면 iOS
  }

  apply()
})()
