import to from 'await-to-js'
import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import {
  RETRY_COUNT,
  SEARCH_ENDPOINT_LEGACY,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import { DocumentAppInitialize } from './AppInitialize'
import { getResultFor } from '../common'
import { FunnelFromIdTypes } from '../../referrer/constants'
import {
  addExperimentXSearchHeader,
  addFunnelFromXSearchHeader,
  addXSearchHeaders,
} from '../../_app/utils/addCustomHeader'
import { AppType } from '../../_app/context/FoundationProvider'
import { SearchExperimentResponseType } from './Experiment'
import {
  SearchExperimentReferrerType,
  SearchReferrerType,
} from '../../referrer/types'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import { DocumentHotKeywords } from '../../hot-keyword/services'
import { FleaMarketRelatedKeywordsResponse } from '../../related-keyword/services/FleaMarketRelatedKeywords'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
} from '../../plantae/plugins'

let searchV2: ServiceSearchV2 | null = null

export function getServiceSearchV2({
  app,
  authToken,
}: {
  app: AppType
  authToken: string
}) {
  if (searchV2) {
    return searchV2
  }

  return (searchV2 = new ServiceSearchV2({
    baseUrl: SEARCH_ENDPOINT_LEGACY,
    app,
    authToken,
  }))
}

export class ServiceSearchV2 {
  private client: AxiosInstance
  private app: AppType

  constructor({
    baseUrl,
    app,
    authToken,
  }: {
    baseUrl: string
    app: AppType
    authToken: string
  }) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    daangnAxiosInterceptors({
      client: this.client,
      plugins: [
        plantaeAuthPlugin({ fallbackAuthToken: authToken }),
        plantaeRequestIdPlugin(),
        plantaeKarrotSessionIdPlugin({ app }),
        plantaeUserAgentPlugin({ userAgent: app.userAgent }),
        plantaeCommonHeadersPlugin(),
        plantaeSearchOriginPlugin(),
      ],
    })

    axiosRetry(this.client, {
      retries: RETRY_COUNT,
      retryDelay: () => 0,
      retryCondition: () => true,
    })

    this.app = app
  }

  /**
   * 검색 초기 데이터 가져오기(실험정보, impression 정보)
   */
  async getAppInitialize(): Promise<DocumentAppInitialize | null> {
    const [error, resp] = await to(
      this.client.get<DocumentAppInitialize>(`/api/v2/initialize`)
    )

    if (error) {
      captureException(error)
      return null
    }
    if (!resp?.data) {
      return null
    }

    return resp.data
  }

  /**
   * 인기 검색어 목록
   * @param regionId
   * @param userId
   * @param referrer
   */
  async getHotKeywords({
    regionId,
    referrer,
  }: {
    regionId: number
    referrer: {
      funnelFromId: FunnelFromIdTypes
      experiment: SearchExperimentReferrerType
    }
  }) {
    const [error, resp] = await to(
      this.client.get<DocumentHotKeywords>(`/api/v2/hotkeyword`, {
        params: {
          region_id: regionId,
          size: 10,
        },
        headers: {
          ...addFunnelFromXSearchHeader({
            funnelFromId: referrer.funnelFromId,
          }),
          ...addExperimentXSearchHeader(referrer.experiment),
        },
      })
    )

    if (error) {
      captureException(error)
      return null
    }

    if (!resp?.data) {
      return null
    }

    return {
      ...resp.data,
      [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
        resp.headers
      ),
    }
  }

  /**
   * 실험 커스텀 트리거가 되었을 경우 실험 정보를 가져오기 위한 API
   */
  async getSearchExperiment(referrer: SearchExperimentReferrerType) {
    const [error, resp] = await to(
      this.client.get<SearchExperimentResponseType>(
        `/api/v2/experiments/custom/infos`,
        {
          headers: addExperimentXSearchHeader(referrer),
        }
      )
    )

    if (error) {
      captureException(error)
      return null
    }
    if (!resp?.data) {
      return null
    }

    return getResultFor(resp.data)
  }

  /**
   * 중고거래 연관검색어(TOP, FILTER 용)
   * @param keyword
   * @param regionId
   */
  async getFleaMarketRelatedKeywords({
    regionId,
    keyword,
    referrer,
  }: {
    regionId: number
    keyword: string
    referrer: SearchReferrerType
  }) {
    const [error, resp] = await to(
      this.client.get<FleaMarketRelatedKeywordsResponse>(
        `/api/v2/flea_market/related_keyword`,
        {
          params: {
            region_id: regionId,
            keyword,
          },
          headers: {
            ...addXSearchHeaders({
              ...referrer,
            }),
          },
        }
      )
    )

    if (error) {
      captureException(error)
      return null
    }
    if (!resp?.data) {
      return null
    }

    return getResultFor(resp.data)
  }
}
