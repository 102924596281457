import 'core-js/actual' // polyfill all actual features - stable ES, web standards and stage 3 ES proposals
import 'regenerator-runtime/runtime'
import '@stackflow/plugin-basic-ui/index.css' // stackflow
import './_app/styles/globalSeedDesign' // seed-design
import './_app/styles/global.scss'
import '@daangn/karrot-clothes/lib/index.css'
import '@daangn/search-sdk/lib/index.css'
import '@daangn/pull-to-refresh/lib/index.css'
import '@daangn/karrotflyer/index.css'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import * as SentryBrowser from '@sentry/browser'
import { datadogRum } from '@datadog/browser-rum'
import { install } from 'resize-observer'
import App from './App'
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  SENTRY_DSN,
  STAGE,
  VERSION,
} from './_app/constants/common'

if (!window.ResizeObserver) {
  install()
}

if (STAGE === 'production') {
  const isAffectByIssue3388 = navigator.userAgent.includes('Chrome/74.0.3729')

  Sentry.init({
    environment: STAGE,
    release: VERSION,
    dsn: SENTRY_DSN,
    integrations: [
      new SentryBrowser.BrowserTracing(),
      new SentryBrowser.Integrations.TryCatch({
        requestAnimationFrame: !isAffectByIssue3388,
      }),
    ],
    ignoreErrors: [
      'Java exception was raised during method invocation', // 안드로이드 앱 인스턴스가 내려갔는데, 브릿지 호출 시 발생하는 에러로 무시처리 (유저 영향 X)
      'ResizeObserver loop limit exceeded', // 크롬 일부 버전에서만 발생하는 에러로, 무해하다고 함.
      'Request aborted',
      'timeout of 0ms exceeded',
      'timeout exceeded',
    ],
    tracesSampleRate: 0,
    sampleRate: 0.1,
  })

  // datadog
  datadogRum.init({
    env: STAGE,
    version: VERSION,
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'search-webview',
    sessionSampleRate: 0.2, // 0.2% of sessions will be recorded
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask', // sessionReplay를 안쓰고 있지만, 보수적으로 모든 항목에 대해 마스킹으로 기본 설정
  })
}

const container = document.getElementById('root') as HTMLElement

const root = createRoot(container)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
