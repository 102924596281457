import { SafeAreaInsetTop } from '../_app/components/safe-area-inset/SafeAreaInset'
import { clientEnvironment } from '../bridge/utils/bridgeMaker'
import { SearchInputNavbar } from '@daangn/search-sdk'
import { useCallback } from 'react'
import AggregatedTabsSkeleton from '../aggregated/components/skeleton/SkeletonAggregatedTabs'

interface PageSearchResultSkeletonProps {
  query: string
  onBackClick: () => void
}

const PageSearchResultSkeleton = (props: PageSearchResultSkeletonProps) => {
  const emptyHandler = useCallback(() => {}, [])

  // iOS 초기 1회 덜그덕거리는 렌더링 수정
  if (
    clientEnvironment.isIOS &&
    !localStorage.getItem('env(safe-area-inset-top)')
  ) {
    return null
  }

  return (
    <>
      <SafeAreaInsetTop />
      <SearchInputNavbar
        platform={clientEnvironment.platform}
        inputValue={props.query}
        onBackClick={props.onBackClick}
        onCancelClick={emptyHandler}
        onInputChange={emptyHandler}
        onSubmit={emptyHandler}
        hideRemoveIcon
      />
      <AggregatedTabsSkeleton />
    </>
  )
}

export default PageSearchResultSkeleton
