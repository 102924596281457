import { STAGE } from '../_app/constants/common'
import { LazyLoadRetryOnce } from './LazyLoad'

const internalPages = () => {
  if (STAGE === 'development' || STAGE === 'alpha' || STAGE === 'staging') {
    return {
      Home: LazyLoadRetryOnce(() => import('../_pages/PageHome')),
      GraphQL: LazyLoadRetryOnce(() => import('../_pages/PageEmpty')),
      // TODO: bundle 속도 이슈가 있어서 임시로 분리
      // GraphQL: LazyLoadRetryOnce(() => import('../_pages/PageGraphQL')),
    }
  }

  return {
    Home: LazyLoadRetryOnce(() => import('../_pages/PageEmpty')),
    GraphQL: LazyLoadRetryOnce(() => import('../_pages/PageEmpty')),
  }
}

export const activities = {
  ...internalPages(),
  FleaMarketFilterRegion: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterRegion')
  ),
  FleaMarketFilterPrice: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterPrice')
  ),
  FleaMarketFilterCategory: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterCategory')
  ),
  FleaMarketFilterSort: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterSort')
  ),
  FleaMarketFilterRegionRange: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterRegionRange')
  ),
  Search: LazyLoadRetryOnce(() => import('../_pages/PageAggregatedSearch')),
  SearchDiscover: LazyLoadRetryOnce(
    () => import('../_pages/PageAggregatedSearchDiscover')
  ),
  AppLoginGuide: LazyLoadRetryOnce(() => import('../_pages/PageAppLoginGuide')),
  AppError: LazyLoadRetryOnce(() => import('../_pages/PageAppError')),
  AdOff: LazyLoadRetryOnce(() => import('../_pages/PageAdOff')),
  ActionSheetCatalogProductAdMore: LazyLoadRetryOnce(
    () => import('../_pages/PageActionSheetCatalogProductAdMore')
  ),
  ActionSheetNeighborAdMore: LazyLoadRetryOnce(
    () => import('../_pages/PageActionSheetNeighborAdMore')
  ),
  ActionSheetNeighborAdInfo: LazyLoadRetryOnce(
    () => import('../_pages/PageActionSheetNeighborAdInfo')
  ),
  ActionSheetKeywordNotification: LazyLoadRetryOnce(
    () => import('../_pages/PageActionSheetKeywordNotification')
  ),
  RegisterUserKeywordError: LazyLoadRetryOnce(
    () => import('../_pages/PageRegisterUserKeywordError')
  ),
}

export type SearchActivitiesType = keyof typeof activities

export const SEARCH_ACTIVITY_KEYS = Object.keys(activities).reduce<{
  [key in keyof typeof activities]: key
}>((acc, cur) => {
  return {
    ...acc,
    [cur]: cur,
  }
}, {} as any)
