import {
  formatDistanceToNowStrict,
  formatDistanceToNow,
  format,
} from 'date-fns'
import { omitBy } from 'lodash-es'
import { ko } from 'date-fns/locale'
import { ConstantObjectType } from '../types'

export const findConstantObjectById = <T extends ConstantObjectType>(
  object: Record<string, T>,
  id: string | number
) => {
  return Object.values(object).find((item) => item.ID === id)
}

export const dateFromNowStrict = (date: Date): string => {
  return formatDistanceToNowStrict(date, {
    locale: ko,
    addSuffix: true,
  })
}

export const dateFromNow = (date: Date): string => {
  return formatDistanceToNow(date, {
    locale: ko,
    includeSeconds: true,
    addSuffix: true,
  })
}

export const dateFormat = (date: Date, formatString: string): string => {
  return format(date, formatString, {
    locale: ko,
  })
}

export const commaNumber = (numberValue: number) => {
  return Number(numberValue).toLocaleString('en')
}

/**
 * Creates an object with all null values removed.
 * @param obj The object to compact
 */
export function compactMap<T extends {}>(
  obj: T
): {
  [key in keyof T]: NonNullable<T[key]>
} {
  return omitBy(obj, (value) => value === null || value === undefined) as any
}

export const take = <T extends unknown>(arr: T[], num = 1): T[] => {
  return arr.filter((_, index) => index < num)
}

export const includes = <T extends U, U>(
  arr: ReadonlyArray<T>,
  el: U
): el is T => {
  return arr.includes(el as T)
}

/**
 * 주어진 배열을 deep copy 하는 함수
 * @param array
 * @returns array
 */
export const arrayDeepCopy = <T>(array: T[]) => {
  if (!Array.isArray(array)) {
    throw new Error('simpleArrayDeepCopy: target variable is not array')
  }
  return JSON.parse(JSON.stringify(array))
}

export const nextTick = <T extends () => void>(
  callback: T,
  option?: {
    timeout: number
  }
) => {
  setTimeout(() => {
    callback()
  }, option?.timeout ?? 0)
}

export const nonNullableTypeSafe = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined
}
