export const BADGE_STYLE_TYPE_TO_PROPS = {
  CLOSED: {
    variant: 'basic',
    type: 'bold',
  },
  RESERVED: {
    variant: 'success',
    type: 'bold',
  },
} as const
