import { ReactNode, RefObject, useEffect, useMemo } from 'react'
interface ImpressionAreaProps {
  observeTargetRef: RefObject<HTMLElement | null>
  children: ReactNode
  threshold?: number
  exposureTime?: number
  onLogging: () => void
}
export const ImpressionArea = ({
  threshold = 0,
  exposureTime = 0,
  ...props
}: ImpressionAreaProps) => {
  const isIntersectionObserverSupported = useMemo(() => {
    if (!Object.prototype.hasOwnProperty.call(window, 'IntersectionObserver')) {
      console.log('Intersection Observer API 미지원 브라우저')
      return false
    }
    return true
  }, [])

  const io = useMemo(() => {
    if (!isIntersectionObserverSupported) return
    let impressionInTimer: ReturnType<typeof setTimeout>

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry

          const handleImpressionIn = () => {
            return setTimeout(() => {
              props.onLogging()
            }, exposureTime)
          }

          if (isIntersecting) {
            impressionInTimer = handleImpressionIn()
          } else {
            impressionInTimer && clearTimeout(impressionInTimer)
          }
        })
      },
      { threshold }
    )

    return observer
  }, [threshold])

  useEffect(() => {
    const el = props.observeTargetRef.current
    el && io?.observe(el)
    return () => {
      el && io?.unobserve(el)
    }
  }, [props.observeTargetRef, io])
  return <>{props.children}</>
}
