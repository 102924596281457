export const COLLECTION_TYPE = {
  AD: 'AD',
  FLEA_MARKET: 'FLEA_MARKET',
  COMMUNITY: 'COMMUNITY',
  BUSINESS: 'BUSINESS',
  PLACE: 'PLACE',
  SHORTCUT: 'SHORTCUT',
  JOB: 'JOB',
  COMMERCE: 'COMMERCE',
  CAR: 'CAR',
  REALTY: 'REALTY',
  KARROT_GROUP: 'KARROT_GROUP',
  UNKNOWN: 'UNKNOWN',
} as const

export const DEFAULT_COLLECTION_RANK = [
  COLLECTION_TYPE.SHORTCUT,
  COLLECTION_TYPE.AD,
  COLLECTION_TYPE.FLEA_MARKET,
  COLLECTION_TYPE.COMMUNITY,
  COLLECTION_TYPE.BUSINESS,
  COLLECTION_TYPE.PLACE,
  COLLECTION_TYPE.JOB,
  COLLECTION_TYPE.CAR,
  COLLECTION_TYPE.REALTY,
  COLLECTION_TYPE.KARROT_GROUP,
  COLLECTION_TYPE.COMMERCE,
]
