import to from 'await-to-js'
import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import camelcaseKeys from 'camelcase-keys'

import {
  KARROT_MARKET_ENDPOINT,
  RETRY_COUNT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import { AppType } from '../../_app/context/FoundationProvider'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
} from '../../plantae/plugins'
import { CreateKeywordNotificationErrorType } from '../../keyword-notification/constants'

interface CreateUserKeywordResponse {
  userKeyword: {
    id: string
    keywordId: number
    userId: number
    keyword: string
    minPrice: number
    maxPrice: number
    categoryIds: number[]
    excludeKeywords: string[]
  }
}

interface CreateUserKeywordErrorResponse {
  type: CreateKeywordNotificationErrorType
  displayType: string
  title: string
  message: {
    locale: string
    text: string
  }
  metadataProtoJson: string
}

let serviceKarrotMarket: ServiceKarrotMarket | null = null

export function getServiceKarrotMarket({
  app,
  authToken,
}: {
  app: AppType
  authToken: string
}) {
  if (serviceKarrotMarket) {
    return serviceKarrotMarket
  }

  return (serviceKarrotMarket = new ServiceKarrotMarket({
    baseUrl: KARROT_MARKET_ENDPOINT,
    app,
    authToken,
  }))
}

export class ServiceKarrotMarket {
  private client: AxiosInstance

  constructor({
    baseUrl,
    app,
    authToken,
  }: {
    baseUrl: string
    app: AppType
    authToken: string
  }) {
    this.client = axios.create({
      baseURL: baseUrl,
    })

    daangnAxiosInterceptors({
      client: this.client,
      plugins: [
        plantaeAuthPlugin({ fallbackAuthToken: authToken }),
        plantaeRequestIdPlugin(),
        plantaeKarrotSessionIdPlugin({ app }),
        plantaeUserAgentPlugin({ userAgent: app.userAgent }),
        plantaeCommonHeadersPlugin(),
        plantaeSearchOriginPlugin(),
      ],
    })

    this.client.interceptors.response.use((response) => {
      response.data = camelcaseKeys(response.data, { deep: true })
      return response
    })
    axiosRetry(this.client, {
      retries: RETRY_COUNT,
      retryDelay: () => 0,
      retryCondition: () => true,
    })
  }

  /**
   * 키워드 알림 등록하기
   * 참고: https://www.notion.so/daangn/Tech-Spec-API-59f5889dfef84028a855b419781301b8
   */
  async createUserKeyword({ keyword }: { keyword: string }) {
    const [error, resp] = await to(
      this.client.post<
        CreateUserKeywordResponse | CreateUserKeywordErrorResponse
      >(
        `/api/v23/keyword/search/user_keywords.json?where=search_result&screen_name=search`,
        {
          keyword,
        }
      )
    )
    if (error || !resp?.data) {
      captureException(error)
      return null
    }
    return resp.data
  }
}
