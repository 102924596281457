import Shortcut, { ShortcutAutoCompleteKeywordType } from './Shortcut'
import { PropOf } from '../_app/types/props-of'
import { Container } from './Shortcuts.css'

interface ShortcutsProps {
  query: string
  shortcutKeywords: ReadonlyArray<ShortcutAutoCompleteKeywordType | null>
  onShortcutClick: PropOf<typeof Shortcut, 'onClick'>
}

const Shortcuts = (props: ShortcutsProps) => {
  if (props.shortcutKeywords.length < 1) {
    return null
  }

  return (
    <article className={Container}>
      {props.shortcutKeywords.map((shortcutKeyword, i) => {
        if (!shortcutKeyword?.name) {
          return null
        }

        return (
          <Shortcut
            key={`shortcut_${shortcutKeyword.name}`}
            index={i}
            query={props.query}
            keyword={shortcutKeyword}
            onClick={props.onShortcutClick}
          />
        )
      })}
    </article>
  )
}

export default Shortcuts
