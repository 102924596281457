export const OVERFLOW_STYLE = {
  UNKNOWN: 'UNKNOWNOVERFLOWSTYLETYPE',
  /**
   * 어떤 종류의 파괴 작업을 나타내는 빨간색 버튼. 삭제 또는 제거 등을 표현
   */
  DESTRUCTIVE: 'DESTRUCTIVE',
  /**
   * 기본 버튼
   */
  NORMAL: 'NORMAL',
  /**
   * 오버플로우 액션시트의 제목
   */
  TITLE: 'TITLE',
  /**
   * 오버플로우 액션시트의 본문
   */
  MESSAGE: 'MESSAGE',
} as const

export type OverflowStyleType =
  (typeof OVERFLOW_STYLE)[keyof typeof OVERFLOW_STYLE]

export const OVERFLOW_ACTION = {
  UNKNOWN: 'UNKNOWNOVERFLOWACTIONTYPE',
  /**
   * url 주소로 post 전송
   */
  POST: 'POST',
  /**
   * url(앱스킴 or 외부링크) 화면으로 이동
   */
  TARGET: 'TARGET',
  /**
   * post 기능과 같으나 이후 아티클 숨기기
   */
  HIDE: 'HIDE',
  /**
   * target 기능과 같으나 이후 아티클 숨기기
   */
  HIDE_WITH_TARGET: 'HIDE_WITH_TARGET',
  /**
   * 아무 일도 하지 않음. OverflowStyleType.title, OverflowStyleType.message를 위한 타입
   */
  DO_NOTHING: 'DO_NOTHING',
} as const

export type OverflowActionType =
  (typeof OVERFLOW_ACTION)[keyof typeof OVERFLOW_ACTION]

export type OverflowType = {
  text: string
  style: OverflowStyleType
  action: {
    type: OverflowActionType
    url: string
  }
}
