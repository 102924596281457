import axios from 'axios'
import axiosRetry from 'axios-retry'

import {
  RETRY_COUNT,
  SEARCH_ENDPOINT_LEGACY,
} from '../../_app/constants/common'
import { addXSearchHeaders } from '../../_app/utils/addCustomHeader'
import { SearchReferrerType } from '../../referrer/types'
import { captureAxiosError } from '../../_app/utils/captureException'
import to from 'await-to-js'
import { DocumentAggregatedPreProcess } from './DocumentAggregatedPreProcess'
import { getResultFor, SearchResponseType } from '../../services/common'

import { AppType } from '../../_app/context/FoundationProvider'

import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'

import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
} from '../../plantae/plugins'
import plantaeKarrotSessionIdPlugin from '../../plantae/plugins/plantaeKarrotSessionIdPlugin'

let serviceCache: ServiceAggregatedPreProcessType | null = null

export function getServiceAggregatedPreProcess({
  app,
  authToken,
}: {
  app: AppType
  authToken: string
}) {
  if (serviceCache) {
    return serviceCache
  }

  return (serviceCache = ServiceAggregatedPreProcess({
    baseUrl: SEARCH_ENDPOINT_LEGACY,
    app,
    authToken: authToken,
  }))
}

type AggregatedPreProcessParamsType = {
  query: string
  regionId: number
  referrer: SearchReferrerType
}

export type ServiceAggregatedPreProcessType = ReturnType<
  typeof ServiceAggregatedPreProcess
>

type AggregatedPreProcessResponseType =
  SearchResponseType<DocumentAggregatedPreProcess>

const ServiceAggregatedPreProcess = ({
  baseUrl,
  app,
  authToken,
}: {
  baseUrl: string
  app: AppType
  authToken: string
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  const client = axios.create({
    baseURL: baseUrl,
    headers: headers,
  })
  daangnAxiosInterceptors({
    client: client,
    plugins: [
      plantaeAuthPlugin({ fallbackAuthToken: authToken }),
      plantaeRequestIdPlugin(),
      plantaeKarrotSessionIdPlugin({ app }),
      plantaeUserAgentPlugin({ userAgent: app.userAgent }),
      plantaeCommonHeadersPlugin(),
      plantaeSearchOriginPlugin(),
    ],
  })

  axiosRetry(client, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
  })

  return {
    /**
     *  검색 전처리 API(탭순서, 추천탭, 오타교정)
     */
    aggregatedPreProcess: async ({
      query,
      regionId,
      referrer,
    }: AggregatedPreProcessParamsType) => {
      const [error, resp] = await to(
        client.post<AggregatedPreProcessResponseType>(
          `/api/v2/preprocess/query`,
          {
            query: query,
            region_id: regionId,
          },
          { headers: addXSearchHeaders({ ...referrer }) }
        )
      )

      if (error) {
        const errorResponse =
          captureAxiosError<AggregatedPreProcessResponseType>(error, {
            excludeErrors: [403, 400],
          })

        return {
          ...getResultFor(errorResponse?.data),
          [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(),
        }
      }

      if (!resp?.data) {
        return null
      }

      return {
        ...getResultFor(resp.data),
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          resp.headers
        ),
      }
    },
  }
}
