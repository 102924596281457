import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { useMemo } from 'react'
import { RegionRangeType } from '../types'

export const REGION_RANGE: Record<
  RegionRangeType,
  {
    ID: RegionRangeType
    LABEL: string
    ORDER: number
  }
> = {
  MY: {
    ID: 'MY',
    LABEL: '가까운 동네',
    ORDER: 2, // (서버) karrotMap에서 2부터 시작
  },
  ADJACENT: {
    ID: 'ADJACENT',
    LABEL: '조금 가까운 동네',
    ORDER: 3,
  },
  RANGE_2: {
    ID: 'RANGE_2',
    LABEL: '조금 먼 동네',
    ORDER: 4,
  },
  RANGE_3: {
    ID: 'RANGE_3',
    LABEL: '먼 동네',
    ORDER: 5,
  },
}

export interface RegionRangeStateType {
  range: RegionRangeType
  count: number
}

export const DEFAULT_REGION_RANGE = REGION_RANGE.RANGE_3.ID

const regionRangeState = atom<RegionRangeStateType>({
  key: 'FLEA_MARKET_FILTER/REGION_RANGE',
  default: {
    range: DEFAULT_REGION_RANGE,
    count: 0,
  },
})

export const useFleaMarketFilterRegionRangeState = () => {
  return useRecoilValue(regionRangeState)
}

export const useFleaMarketFilterRegionRangeActions = () => {
  const setter = useSetRecoilState(regionRangeState)

  return useMemo(
    () => ({
      changeRegionRange: (range: RegionRangeStateType) => setter(range),
      resetRegionRange: () =>
        setter({
          range: DEFAULT_REGION_RANGE,
          count: 0,
        }),
    }),
    [setter]
  )
}

export const isDefaultRegionRange = (range: RegionRangeType) => {
  return range === REGION_RANGE.RANGE_3.ID
}

export const getRegionOrderFromRangeId = (regionId: RegionRangeType) => {
  return Object.values(REGION_RANGE).find(
    (regionRange) => regionRange.ID === regionId
  )?.ORDER
}
