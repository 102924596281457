import { V4CollectionType } from '../../__codegen__/__openapi__/search-server/integratesearch/v4/integratesearch'
import { COLLECTION_TYPE } from '../constants'

export const convertToCollectionRanksConstants = (tabs: V4CollectionType[]) => {
  return tabs
    .map((tab) => {
      switch (tab) {
        case V4CollectionType.ADVERTISEMENT:
          return COLLECTION_TYPE.AD
        case V4CollectionType.SHORTCUT:
          return COLLECTION_TYPE.SHORTCUT
        case V4CollectionType.FLEAMARKET:
          return COLLECTION_TYPE.FLEA_MARKET
        case V4CollectionType.COMMUNITY:
          return COLLECTION_TYPE.COMMUNITY
        case V4CollectionType.COMMERCE:
          return COLLECTION_TYPE.COMMERCE
        case V4CollectionType.BUSINESS_POSTS:
          return COLLECTION_TYPE.BUSINESS
        case V4CollectionType.PLACE:
          return COLLECTION_TYPE.PLACE
        case V4CollectionType.JOB:
          return COLLECTION_TYPE.JOB
        case V4CollectionType.CAR:
          return COLLECTION_TYPE.CAR
        case V4CollectionType.REALTY:
          return COLLECTION_TYPE.REALTY
        case V4CollectionType.GROUP:
          return COLLECTION_TYPE.KARROT_GROUP
        case V4CollectionType.UNSPECIFIED:
        default:
          return COLLECTION_TYPE.UNKNOWN
      }
    })
    .filter(Boolean)
}
