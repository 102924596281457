import { MouseEvent } from 'react'
import { Container, Name, RightArrow, RightArrowPath } from './Shortcut.css'

export interface ShortcutAutoCompleteKeywordType {
  readonly name: string
  readonly targetUri: string
}

interface ShortcutProps {
  query: string
  index: number
  keyword: ShortcutAutoCompleteKeywordType
  onClick: (
    e: MouseEvent<HTMLButtonElement>,
    info: {
      keyword: ShortcutAutoCompleteKeywordType
      query: string
      index: number
    }
  ) => void
}

const Shortcut = (props: ShortcutProps) => {
  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    props.onClick(e, {
      keyword: props.keyword,
      query: props.query,
      index: props.index,
    })
  }

  return (
    <button className={Container} onClick={onClick}>
      <strong className={Name}>{props.keyword.name}</strong>
      <IcChevronRightOutlineXS />
    </button>
  )
}

function IcChevronRightOutlineXS() {
  return (
    <svg
      className={RightArrow}
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={RightArrowPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L5.35355 4.64645C5.54882 4.84171 5.54882 5.15829 5.35355 5.35355L1.35355 9.35355C1.15829 9.54882 0.841709 9.54882 0.646447 9.35355C0.451184 9.15829 0.451184 8.84171 0.646447 8.64645L4.29289 5L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
        fill="#212124"
      />
    </svg>
  )
}

export default Shortcut
