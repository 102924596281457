import type { Plugin } from 'plantae'
import { getKarrotSessionInfo } from '../../bridge/utils/bridgeMaker'
import { AppType } from '../../_app/context/FoundationProvider'
import {
  satisfyAndroidVersion,
  satisfyIosVersion,
} from '../../_app/utils/version'

export default function plantaeKarrotSessionIdPlugin({
  app,
}: {
  app: AppType
}): Plugin {
  return {
    name: 'plugin-karrot-session-id',
    hooks: {
      beforeRequest: async (req) => {
        if (
          satisfyAndroidVersion(app, '<23.6.0') ||
          satisfyIosVersion(app, '<23.5.0')
        ) {
          return req
        }
        const karrotSessionInfo = await getKarrotSessionInfo()
        const karrotSessionId = karrotSessionInfo?.id
        if (karrotSessionId) {
          req.headers.set('x-karrot-session-id', karrotSessionId)
        }
        return req
      },
    },
  }
}
