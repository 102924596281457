import to from 'await-to-js'
import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import {
  RETRY_COUNT,
  SEARCH_ENDPOINT_LEGACY,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import { DocumentUserKeyword } from './UserKeyword'
import { getResultFor } from '../../services/common'
import { FleaMarketFilterRecommendResponseType } from './FleaMarketFilterRecommendType'
import { AppType } from '../../_app/context/FoundationProvider'
import { addExperimentXSearchHeader } from '../../_app/utils/addCustomHeader'
import { SearchExperimentReferrerType } from '../../referrer/types'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
} from '../../plantae/plugins'
import plantaeKarrotSessionIdPlugin from '../../plantae/plugins/plantaeKarrotSessionIdPlugin'

let userKeywordService: UserKeywordService | null = null

export function getUserKeywordService({
  app,
  authToken,
}: {
  app: AppType
  authToken: string
}) {
  if (userKeywordService) {
    return userKeywordService
  }

  return (userKeywordService = new UserKeywordService({
    baseUrl: SEARCH_ENDPOINT_LEGACY,
    app,
    authToken,
  }))
}

export class UserKeywordService {
  private client: AxiosInstance

  constructor({
    baseUrl,
    app,
    authToken,
  }: {
    baseUrl: string
    app: AppType
    authToken: string
  }) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    daangnAxiosInterceptors({
      client: this.client,
      plugins: [
        plantaeAuthPlugin({ fallbackAuthToken: authToken }),
        plantaeRequestIdPlugin(),
        plantaeKarrotSessionIdPlugin({ app }),
        plantaeUserAgentPlugin({ userAgent: app.userAgent }),
        plantaeCommonHeadersPlugin(),
        plantaeSearchOriginPlugin(),
      ],
    })

    axiosRetry(this.client, {
      retries: RETRY_COUNT,
      retryDelay: () => 0,
      retryCondition: () => true,
    })
  }

  /**
   * 키워드 정보 (키워드 알림 등록 여부, 금지 키워드 여부)
   */
  async getUserKeyword({
    query,
    referrer,
  }: {
    query: string
    referrer: {
      experiment: SearchExperimentReferrerType
    }
  }) {
    const [error, resp] = await to(
      this.client.get<DocumentUserKeyword>(`/api/v2/user-keyword`, {
        params: {
          keyword: query,
        },
        headers: {
          ...addExperimentXSearchHeader(referrer.experiment),
        },
      })
    )

    if (error) {
      captureException(error)
      return null
    }
    if (!resp?.data) {
      return null
    }

    return {
      ...resp.data,
      [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
        resp.headers
      ),
    }
  }

  /**
   * 중고거래 검색 필터 추천 (카테고리, 가격)
   */
  getFleaMarketFilterRecommend = async ({
    query,
    regionId,
    referrer,
  }: {
    query: string
    regionId: number
    referrer: {
      experiment: SearchExperimentReferrerType
    }
  }) => {
    const [error, resp] = await to(
      this.client.get<FleaMarketFilterRecommendResponseType>(
        `/api/v2/fleamarket-filter`,
        {
          params: {
            keyword: query,
            region_id: regionId,
          },
          headers: { ...addExperimentXSearchHeader(referrer.experiment) },
        }
      )
    )

    if (error) {
      captureException(error)
      return null
    }

    if (!resp?.data) {
      return null
    }

    return {
      ...getResultFor(resp.data),
      [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
        resp.headers
      ),
    }
  }
}
