import { createContext, ReactNode, useContext, useEffect } from 'react'
import { SearchActivitiesType } from '../../stackflow/SearchActivities'

type ObservePayload = { key: SearchActivitiesType; type: 'scrollToTop' }
type Observe = (observe: ObservePayload) => void

let installed = false

function installObserver() {
  if (installed) {
    throw new Error('installDetector is already installed.')
  }

  installed = true

  const observer: Set<Observe> = new Set()

  function subscribe(observe: Observe) {
    observer.add(observe)

    return () => {
      observer.delete(observe)
    }
  }

  function fireEvent(payload: ObservePayload) {
    observer.forEach((observe) => observe(payload))
  }

  return {
    subscribe,
    fireEvent,
  }
}

const ObserverContext = createContext<ReturnType<
  typeof installObserver
> | null>(null)

const observerInstance = installObserver()

export const SearchGlobalEventBusProvider = (props: {
  children: ReactNode
}) => {
  return (
    <ObserverContext.Provider value={observerInstance}>
      {props.children}
    </ObserverContext.Provider>
  )
}

export const useSearchGlobalEventEffect = (observe: Observe, deps?: any[]) => {
  const observer = useContext(ObserverContext)

  useEffect(() => {
    return observer?.subscribe(observe)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer, ...(deps ?? [])])
}

export const useSearchGlobalEvent = () => {
  const observer = useContext(ObserverContext)

  if (!observer) {
    throw new Error('not found ObserverContext')
  }

  return {
    fireGlobalEvent: observer.fireEvent,
  }
}
