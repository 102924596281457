import { ReactNode } from 'react'
import * as css from './IconContainerRecentSearch.css'

interface IconContainerRecentSearchProps {
  children: ReactNode
}
const IconContainerRecentSearch = (props: IconContainerRecentSearchProps) => {
  return <div className={css.circularBorder}>{props.children}</div>
}
export default IconContainerRecentSearch
