import { Component, ErrorInfo, ReactNode } from 'react'
import { captureException } from '../../utils/captureException'
import BridgeTimeoutError from '../../../error/class/BridgeTimeoutError'

interface ErrorBoundaryProps {
  children?: ReactNode
  fallback: ReactNode
}

class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error instanceof BridgeTimeoutError) {
      return
    }

    captureException(error, { extra: errorInfo })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback
    }

    return this.props.children
  }
}

export default ErrorBoundary
