import { ImgHTMLAttributes, SyntheticEvent, useCallback, useRef } from 'react'
import { image } from './LazyImage.css'

interface LazyImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallbackSrc?: string
}

const LazyImage = (props: LazyImageProps) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const { fallbackSrc, alt, className, loading, ...rest } = props

  const onError = useCallback(
    ({ currentTarget }: SyntheticEvent<HTMLImageElement>) => {
      if (!fallbackSrc) {
        return
      }

      currentTarget.onerror = null

      if (imgRef.current) {
        imgRef.current.src = fallbackSrc
      }
    },
    [imgRef, fallbackSrc]
  )

  const onLoad = useCallback(() => {
    imgRef.current?.setAttribute('data-image-loaded', 'true')
  }, [imgRef])

  return (
    <img
      {...rest}
      ref={imgRef}
      loading={loading || 'lazy'}
      className={[image, className].join(' ')}
      data-image-loaded={'false'}
      onError={onError}
      onLoad={onLoad}
      alt={alt}
    />
  )
}

export default LazyImage
