import {
  installKarrotBridgeDriver,
  makeKarrotBridge,
} from '@daangn/karrotbridge'
import {
  DEFAULT_APP,
  DEFAULT_GEOLOCATION,
  DEFAULT_REGION,
  DEFAULT_USER,
} from '../constants'
import pRetry from 'p-retry'
import pTimeout from 'p-timeout'
import { QueryFromIdTypes } from '../../referrer/constants'
import { getFoundationOverridePersistedState } from '../../_app/stores/PersistentStore'
import BridgeTimeoutError from '../../error/class/BridgeTimeoutError'
import {
  makeSearchBridge,
  installSearchBridgeDriver,
} from '@daangn/search-bridge-sdk'

const BRIDGE_TIMEOUT_MS = 250
const SHORT_BRIDGE_TIMEOUT_MS = 50

const { driver: karrotDriver } = installKarrotBridgeDriver()
export const karrotBridge = makeKarrotBridge({ driver: karrotDriver })

/**
 * @TODO
 * `installSearchBridgeDriver()` 시, window.native 객체에 searchBridge 인터페이스(onSearchMessage)를 추가해줌.
 * 만약 karrotBridge 보다 먼저 install 된다면, window.native 객체를 찾지 못해 할당하지 않아, 기능에는 문제 없지만, 에러로그가 남음
 *
 * 추후 karrotBridge, searchBridge package를 하나로 합치는 작업을 통해, 사용하는 쪽에서 install 순서를 고려하지 않아도 되도록 처리 필요
 */
const { driver: searchDriver } = installSearchBridgeDriver()
const searchBridge = makeSearchBridge({ driver: searchDriver })
const environment = karrotBridge.driver.getCurrentEnvironment()

export const clientEnvironment = {
  isIOS: 'Cupertino' === environment,
  isAndroid: 'Android' === environment,
  isWeb: 'Web' === environment,
  platform: environment,
}
export type ClientEnvironmentType = typeof clientEnvironment

export const executeByEnvironment = ({
  onApp,
  onAndroid,
  onIos,
  onWeb,
}: {
  onApp?: () => void
  onAndroid?: () => void
  onIos?: () => void
  onWeb?: () => void
}) => {
  switch (clientEnvironment.platform) {
    case 'Android':
      onAndroid?.()
      onApp?.()
      break
    case 'Cupertino':
      onIos?.()
      onApp?.()
      break
    case 'Web':
      onWeb?.()
      break
  }
}

export async function getKarrotSessionInfo() {
  try {
    const sessionInfo = await pRetry(
      () =>
        pTimeout(karrotBridge.getKarrotAnalyticsSessionInfo({}), {
          milliseconds: SHORT_BRIDGE_TIMEOUT_MS,
          message: new BridgeTimeoutError(
            'karrotBridge.getKarrotAnalyticsSessionInfo({}) timeout'
          ),
        }),
      {
        retries: 1,
        minTimeout: 16,
      }
    )
    return sessionInfo.info.karrotAnalyticsSession
  } catch {
    return Promise.resolve(null)
  }
}
export async function getApp() {
  const overrideApp = getFoundationOverridePersistedState().app

  if (overrideApp) {
    return overrideApp
  }

  if (DEFAULT_APP) {
    return DEFAULT_APP
  }
  const app = await pRetry(
    () =>
      pTimeout(karrotBridge.getAppInfo({}), {
        milliseconds: BRIDGE_TIMEOUT_MS,
        message: new BridgeTimeoutError('karrotBridge.getAppInfo({}) timeout'),
      }),
    {
      retries: 3,
      minTimeout: 16,
    }
  )

  return app.info.app ?? undefined
}
export async function getRegion() {
  const overrideRegion = getFoundationOverridePersistedState().region
  if (overrideRegion) {
    return overrideRegion
  }

  if (DEFAULT_REGION) {
    return DEFAULT_REGION
  }

  const region = await pRetry(
    () =>
      pTimeout(karrotBridge.getRegionInfo({}), {
        milliseconds: BRIDGE_TIMEOUT_MS,
        message: new BridgeTimeoutError(
          'karrotBridge.getRegionInfo({}) timeout'
        ),
      }),
    {
      retries: 3,
      minTimeout: 16,
    }
  )

  return region.info.region ?? undefined
}
export async function getUser() {
  const overrideUser = getFoundationOverridePersistedState().user

  if (overrideUser) {
    return overrideUser
  }

  if (DEFAULT_USER) {
    return DEFAULT_USER
  }

  const user = await pRetry(
    () =>
      pTimeout(karrotBridge.getUserInfo({}), {
        milliseconds: BRIDGE_TIMEOUT_MS,
        message: new BridgeTimeoutError('karrotBridge.getUserInfo({}) timeout'),
      }),
    {
      retries: 3,
      minTimeout: 16,
    }
  )

  return user.info.user ?? undefined
}

export async function getGeolocation({
  timeout = BRIDGE_TIMEOUT_MS,
}: {
  timeout?: number
}) {
  if (DEFAULT_GEOLOCATION) {
    return DEFAULT_GEOLOCATION
  }

  try {
    const resp = await pRetry(
      () => {
        return pTimeout(
          karrotBridge.getCurrentPosition({
            systemSettingPopupEnabled: false,
          }),
          {
            milliseconds: timeout,
            message: new BridgeTimeoutError(
              'karrotBridge.getCurrentPosition({}) timeout'
            ),
          }
        )
      },
      {
        retries: 1,
        minTimeout: 16,
      }
    )

    return resp.geolocation
  } catch (e) {
    console.error(e)
    return Promise.resolve(null)
  }
}

export const sendBridgeHomeKeywordClicked = (payload: {
  query: string
  queryFrom: QueryFromIdTypes
}) => {
  searchBridge.sendHomeKeywordClicked(payload)
}

export const sendBridgeInputFocusOut = () => {
  searchBridge.blurSearchInput({})
}
