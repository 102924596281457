import { SnackbarProvider } from '@daangn/sprout-components-snackbar'
import { memo, useCallback } from 'react'
import { SearchFunnelIdProvider } from './referrer/context/SearchFunnelIdProvider'
import { SearchQueryIdProvider } from './referrer/context/SearchQueryIdProvider'
import { RelayEnvironmentWithContextProvider } from './relay/environment'
import { Stack } from './stackflow/stackflow'
import ErrorBoundary from './_app/components/error/ErrorBoundary'
import SimpleError from './_app/components/error/SimpleError'
import { ImpressionLoggerRoot } from './_app/components/impression/Impression'
import EntryPointSkeleton from './_app/components/skeleton/EntryPointSkeleton'
import { AppInitializeProvider } from './_app/context/AppInitializeProvider'
import { FoundationProvider } from './_app/context/FoundationProvider'
import GlobalMutableStateProvider from './_app/context/GlobalMutableStateProvider'
import { SearchGlobalEventBusProvider } from './_app/context/SearchGlobalEventBus'
import SupportToolsProvider from './_app/context/SupportToolsProvider'

const App = () => {
  const handleErrorReload = useCallback(() => {
    window.location.reload() // chunk error 시 reload 유도를 위함
  }, [])

  return (
    <ErrorBoundary fallback={<SimpleError retry={handleErrorReload} />}>
      <EntryPointSkeleton>
        <FoundationProvider>
          <SupportToolsProvider>
            <AppInitializeProvider>
              <GlobalMutableStateProvider>
                <SearchFunnelIdProvider>
                  <SearchQueryIdProvider>
                    <ImpressionLoggerRoot>
                      <RelayEnvironmentWithContextProvider>
                        <SearchGlobalEventBusProvider>
                          <SnackbarProvider>
                            <Stack />
                          </SnackbarProvider>
                        </SearchGlobalEventBusProvider>
                      </RelayEnvironmentWithContextProvider>
                    </ImpressionLoggerRoot>
                  </SearchQueryIdProvider>
                </SearchFunnelIdProvider>
              </GlobalMutableStateProvider>
            </AppInitializeProvider>
          </SupportToolsProvider>
        </FoundationProvider>
      </EntryPointSkeleton>
    </ErrorBoundary>
  )
}

export default memo(App)
