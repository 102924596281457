import { forwardRef, PropsWithChildren } from 'react'
import classNames from 'classnames'
import {
  TouchableHighlight,
  TouchableHighlightAs,
  TouchableHighlightElement,
} from '../_app/components/touchable-highlight'
import * as css from './CardCommunityItem.css'

interface CardCommunityItemProps {
  as: TouchableHighlightAs
  role?: string
  className?: string
  hideBorder?: boolean
  onClick?: () => void
}

const CardCommunityItem = forwardRef<
  TouchableHighlightElement<TouchableHighlightAs>,
  PropsWithChildren<CardCommunityItemProps>
>(({ as, role, className, hideBorder = false, children, onClick }, ref) => {
  return (
    <TouchableHighlight
      ref={ref}
      as={as}
      role={role}
      data-hide-border={hideBorder}
      className={classNames(css.container, className)}
      onClick={onClick}
    >
      {children}
    </TouchableHighlight>
  )
})

export default CardCommunityItem
