import {
  useReducer,
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from 'react'
import { v4 } from 'uuid'

type State = {
  query: string
  queryId: string
}

export type SearchQueryIdReducerAction = {
  type: 'update'
  value: State
}

export const searchQueryIdReducer = (
  state: State,
  action: SearchQueryIdReducerAction
) => {
  // queryId는 현재 검색결과에 대한 고유값이다.
  // 따라서, 검색결과가 변경되었을때 동일한 쿼리더라도 queryId를 새로 생성한다.
  switch (action.type) {
    case 'update': {
      return { ...state, queryId: action.value.queryId }
    }
    default:
      throw new Error('Given action type does not exist.')
  }
}

const SearchQueryIdContext = createContext<State | null>(null)
const SearchQueryIdUpdaterContext = createContext<
  | ((
      action: 'update',
      payload: {
        query: string
        queryId?: string
      }
    ) => void)
  | null
>(null)

interface SearchQueryIdProviderProps {
  children: ReactNode
}

export const SearchQueryIdProvider = (props: SearchQueryIdProviderProps) => {
  const [state, dispatch] = useReducer(searchQueryIdReducer, {
    query: '',
    queryId: v4(),
  })

  const handleUpdater = useCallback(
    (
      action: 'update',
      payload: {
        query: string
        queryId?: string
      }
    ) => {
      const queryId = payload.queryId ?? v4()
      dispatch({
        type: action,
        value: {
          query: payload.query,
          queryId,
        },
      })
    },
    [dispatch]
  )

  return (
    <SearchQueryIdContext.Provider value={state}>
      <SearchQueryIdUpdaterContext.Provider value={handleUpdater}>
        {props.children}
      </SearchQueryIdUpdaterContext.Provider>
    </SearchQueryIdContext.Provider>
  )
}

export function useSearchQueryId() {
  const state = useContext(SearchQueryIdContext)
  const updater = useContext(SearchQueryIdUpdaterContext)

  if (!state) {
    throw new Error('not found SearchQueryIdContext')
  }

  if (!updater) {
    throw new Error('not found SearchQueryIdUpdaterContext')
  }

  return {
    /** queryId는 현재 검색결과에 대한 고유값*/
    queryId: state.queryId,
    updateSearchQueryId: updater,
  }
}
