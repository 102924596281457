import {
  Category,
  CategoryClose,
  CategoryContainer,
  CategoryText,
} from './SearchInputCategoryLabel.css'
import { MouseEvent } from 'react'

interface SearchInputCategoryLabelProps {
  name: string
  onRemove?: (e: MouseEvent<HTMLDivElement>) => void
}

const SearchInputCategoryLabel = (props: SearchInputCategoryLabelProps) => {
  return (
    <div className={CategoryContainer}>
      <label className={Category}>
        <span
          className={CategoryText}
          role="search"
          aria-label={`카테고리 ${props.name}`}
        >
          {props.name}
        </span>
        <div
          role="button"
          aria-label={'선택된 카테고리 지우기'}
          className={CategoryClose}
          onClick={(e) => {
            e.stopPropagation()
            props.onRemove?.(e)
          }}
        >
          <IcWindowCloseOutlineXS />
        </div>
      </label>
    </div>
  )
}

export function IcWindowCloseOutlineXS() {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.35358 0.646447C1.15832 0.451184 0.841739 0.451184 0.646477 0.646447C0.451215 0.841709 0.451215 1.15829 0.646477 1.35355L3.29292 4L0.646477 6.64645C0.451215 6.84171 0.451215 7.15829 0.646477 7.35355C0.841739 7.54882 1.15832 7.54882 1.35358 7.35355L4.00003 4.70711L6.64648 7.35355C6.84174 7.54882 7.15832 7.54882 7.35358 7.35355C7.54885 7.15829 7.54885 6.84171 7.35358 6.64645L4.70714 4L7.35358 1.35355C7.54885 1.15829 7.54885 0.841709 7.35358 0.646447C7.15832 0.451184 6.84174 0.451184 6.64648 0.646447L4.00003 3.29289L1.35358 0.646447Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SearchInputCategoryLabel
