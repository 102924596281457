import { vars } from '@seed-design/design-token'
const IconClose = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.20303 2.20303C2.41818 1.98788 2.76701 1.98788 2.98216 2.20303L7 6.22088L11.0178 2.20303C11.233 1.98788 11.5818 1.98788 11.797 2.20303C12.0121 2.41818 12.0121 2.76701 11.797 2.98216L7.77913 7L11.797 11.0178C12.0121 11.233 12.0121 11.5818 11.797 11.797C11.5818 12.0121 11.233 12.0121 11.0178 11.797L7 7.77913L2.98216 11.797C2.76701 12.0121 2.41818 12.0121 2.20303 11.797C1.98788 11.5818 1.98788 11.233 2.20303 11.0178L6.22087 7L2.20303 2.98216C1.98788 2.76701 1.98788 2.41818 2.20303 2.20303Z"
        fill={vars.$scale.color.gray600}
      />
    </svg>
  )
}

export default IconClose
