import {
  findFunnelFromValueById,
  findQueryFromValueById,
} from '../../referrer/utils'
import {
  SearchExperimentReferrerType,
  SearchReferrerType,
} from '../../referrer/types'
import { FunnelFromIdTypes } from '../../referrer/constants'

interface AddXSearchHeadersProps extends SearchReferrerType {
  moreHeaders?: Record<string, string>
}

export const addXSearchHeaders = ({
  queryId,
  funnelFromId,
  queryFromId,
  searchFunnelId,
  moreHeaders,
  tab,
  screenDepthName,
  experiment,
}: AddXSearchHeadersProps) => {
  const queryFrom = findQueryFromValueById(queryFromId)
  const funnelFrom = findFunnelFromValueById(funnelFromId)

  return {
    'x-search-funnel-id': searchFunnelId,
    'x-search-query-id': queryId,
    'x-search-query-from': queryFrom,
    'x-search-funnel-from': funnelFrom,
    'x-search-tab': tab.toLowerCase(),
    'x-search-screen-depth-name': screenDepthName.toLowerCase(),
    ...addExperimentXSearchHeader(experiment),
    ...moreHeaders,
  }
}

export const addExperimentXSearchHeader = (
  experiment: SearchExperimentReferrerType
) => {
  return {
    ...(experiment.headerSegmentKey &&
      experiment.headerSegmentValue && {
        [experiment.headerSegmentKey]: experiment.headerSegmentValue,
      }),
  }
}

export const addFunnelFromXSearchHeader = ({
  funnelFromId,
}: {
  funnelFromId: FunnelFromIdTypes
}) => {
  const funnelFrom = findFunnelFromValueById(funnelFromId)

  return {
    'x-search-funnel-from': funnelFrom,
  }
}
