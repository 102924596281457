import { historySyncPlugin } from '@stackflow/plugin-history-sync'
import { basicUIPlugin } from '@stackflow/plugin-basic-ui'
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic'
import { stackflow } from '@stackflow/react'
import { devtoolsPlugin } from '@stackflow/plugin-devtools'
import { Environment } from '@daangn/karrotbridge/lib/core/getCurrentEnvironment'
import { vars } from '@seed-design/design-token'
import { clientEnvironment } from '../bridge/utils/bridgeMaker'
import { activities } from './SearchActivities'
import { searchStackDepthChangePlugin } from './plugin/searchStackDepthChangePlugin'
import { searchKarrotAnalyticsPlugin } from './plugin/searchKarrotAnalyticsPlugin'
import { iOSScrollToTopPlugin } from './plugin/searchIOSScrollToTopPlugin'
import { STAGE } from '../_app/constants/common'

export const stackflowThemeBy = (environment: Environment) => {
  if (environment === 'Cupertino') {
    return 'cupertino'
  }
  if (environment === 'Android') {
    return 'android'
  }
  // Web
  return 'cupertino'
}

const developmentOnlyPlugins = () => {
  if (STAGE === 'alpha' || STAGE === 'development') {
    return [devtoolsPlugin()]
  }
  return []
}

export const { Stack, useFlow } = stackflow({
  transitionDuration: 350,
  activities: activities,
  plugins: [
    basicUIPlugin({
      theme: stackflowThemeBy(clientEnvironment.platform),
      backgroundColor: vars.$semantic.color.paperDefault,
    }),
    basicRendererPlugin(),
    historySyncPlugin({
      useHash: false,
      routes: {
        Home: '/',
        GraphQL: '/___graphql',
        Search: '/search',
        SearchDiscover: '/search/discover',
        AppLoginGuide: '/app/login-guide',
        AppError: '/app/error',
        AdOff: '/app/ad-off',
        ActionSheetCatalogProductAdMore:
          '/app/action-sheet/catalog-product-ad/more',
        ActionSheetNeighborAdMore: '/app/action-sheet/neighbor-ad/more',
        ActionSheetNeighborAdInfo: '/app/action-sheet/neighbor-ad/info',
        ActionSheetKeywordNotification:
          '/app/action-sheet/keyword-notification',
        RegisterUserKeywordError: '/keyword-notification/error',
        FleaMarketFilterRegion: '/search/flea-market/filter/region',
        FleaMarketFilterPrice: '/search/flea-market/filter/price',
        FleaMarketFilterCategory: '/search/flea-market/filter/category',
        FleaMarketFilterSort: '/search/flea-market/filter/sort',
        FleaMarketFilterRegionRange: '/search/flea-market/filter/region-range',
      },
      fallbackActivity: () => 'Home',
    }),
    searchStackDepthChangePlugin(),
    searchKarrotAnalyticsPlugin(),
    iOSScrollToTopPlugin(),
    ...developmentOnlyPlugins(),
  ],
})
