import to from 'await-to-js'
import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import { RETRY_COUNT, X_PROPERTY_ENDPOINT } from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import { AppType } from '../../_app/context/FoundationProvider'

let xProperty: ServiceXProperty | null = null

export function getServiceXProperty({
  app,
  authToken,
}: {
  app: AppType
  authToken: string
}) {
  if (xProperty) {
    return xProperty
  }

  return (xProperty = new ServiceXProperty({
    baseUrl: X_PROPERTY_ENDPOINT,
    authToken,
    app,
  }))
}

interface SharingTextByCategoryType {
  flea_market_price_free_texts: SharingTextByCategoryItemType[]
}

export interface SharingTextByCategoryItemType {
  android_text: string
  ios_text: string
  category_id: number | null | undefined
}

export class ServiceXProperty {
  private client: AxiosInstance
  private sharingTextByCategory: SharingTextByCategoryType | null = null
  private sharingTextByCategoryPromise: Promise<SharingTextByCategoryType | null> | null =
    null

  constructor({
    baseUrl,
    authToken,
    app,
  }: {
    baseUrl: string
    authToken: string
    app: AppType
  }) {
    this.client = axios.create({
      baseURL: baseUrl,
    })
    daangnAxiosInterceptors({
      client: this.client,
      plugins: [
        plantaeAuthPlugin({ fallbackAuthToken: authToken }),
        plantaeRequestIdPlugin(),
        plantaeUserAgentPlugin({ userAgent: app.userAgent }),
        plantaeCommonHeadersPlugin(),
      ],
    })

    axiosRetry(this.client, {
      retries: RETRY_COUNT,
      retryDelay: () => 0,
      retryCondition: () => true,
    })
  }

  getSharingTextByCategory = async () => {
    if (this.sharingTextByCategory) {
      return this.sharingTextByCategory // 캐싱
    }

    if (!this.sharingTextByCategoryPromise) {
      this.sharingTextByCategoryPromise = this.client
        .get<{ properties: SharingTextByCategoryType }>(
          `/v1/countries/kr/properties/flea_market_price_free_texts`
        )
        .then(({ data }) => data?.properties || null)
    }

    const [error, properties] = await to(this.sharingTextByCategoryPromise)

    if (error) {
      captureException(error)
      return null
    }

    if (!properties) {
      return null
    }

    this.sharingTextByCategory = properties

    return this.sharingTextByCategory
  }
}
