import { ReactNode, useEffect, useMemo } from 'react'
import { LogType } from '../../../core/common/types'
import { useImpression } from './ImpressionRoot'
import pageImpressionTracker from '../../../core/tracker/pageImpressionTracker'

interface ImpressionObserverProps {
  log: LogType
  observeTargetRef: { current: HTMLElement | null }
  children: ReactNode
}

export const ImpressionObserver = (props: ImpressionObserverProps) => {
  const { impressionOptions, initialized, logger } = useImpression()
  const io = useMemo(() => {
    if (initialized) {
      const impressionObserver = pageImpressionTracker(
        logger,
        impressionOptions,
        props.log
      )
      return impressionObserver
    }
  }, [initialized, impressionOptions, props.log])

  useEffect(() => {
    const el = props.observeTargetRef.current
    el && io && io.observe(el)
    return () => {
      el && io && io.unobserve(el)
    }
  }, [io, props.log])
  return <>{props.children}</>
}
