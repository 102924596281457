import snakecaseKeys from 'snakecase-keys'
import { omitBy } from 'lodash-es'
import { LOG_STATUS } from './constants'
import { LogType } from './types'

/**
 * element가 viewport 안에 노출되었는지 확인하는 함수
 * @param element target element to assert that element is visible in viewport
 * @param strict If true, all 4 corners in viewport. If false, at least 1 corner is in viewport
 * @param threshold ratio of element to be visible in viewport
 */
export const isInViewport = (
  element: HTMLElement,
  strict: boolean = false,
  threshold: number = 1
): boolean => {
  const elRect = element.getBoundingClientRect()
  const viewportOffset = getViewportOffset(element, threshold)
  const viewportRect = {
    top: 0,
    left: 0,
    bottom: window.innerHeight || document.documentElement.clientHeight,
    right: window.innerWidth || document.documentElement.clientWidth,
  }
  if (strict) {
    return (
      elRect.top >= viewportRect.top - viewportOffset.top &&
      elRect.left >= viewportRect.left - viewportOffset.left &&
      elRect.bottom <= viewportRect.bottom + viewportOffset.bottom &&
      elRect.right <= viewportRect.right + viewportOffset.right
    )
  } else {
    return (
      elRect.top <= viewportRect.bottom + viewportOffset.bottom &&
      elRect.left <= viewportRect.right + viewportOffset.right &&
      elRect.bottom >= viewportRect.top - viewportOffset.top &&
      elRect.right >= viewportRect.left - viewportOffset.left
    )
  }
}

/**
 * 주어진 threshold 값만큼 viewport offset 값을 반환하는 함수
 */
export const getViewportOffset = (
  element: HTMLElement,
  threshold: number = 1
) => {
  const inversedThreshold = 1 - threshold
  const elRect = element.getBoundingClientRect()
  const offsetRect = {
    top: elRect.height * inversedThreshold,
    left: elRect.width * inversedThreshold,
    bottom: elRect.height * inversedThreshold,
    right: elRect.width * inversedThreshold,
  }
  return offsetRect
}
/**
 * 주어진 element가 실제 viewport에 영역을 차지하고 있는지 체크하는 함수
 */
export const isElementVisible = (element: HTMLElement) => {
  return element.offsetHeight !== 0 || element.offsetWidth !== 0
}

export const visualizeLogging = () => {
  const initState = {
    visualiseObserver: null,
    doVisualise: null,
    disconnectVisualise: null,
  }
  if (!Object.prototype.hasOwnProperty.call(window, 'MutationObserver')) {
    console.log('Mutation Observer API 미지원 브라우저')
    return initState
  }
  const doColor = (target: HTMLElement) => {
    switch (target.dataset.logStatus) {
      case LOG_STATUS.OBSERVED: {
        target.style.backgroundColor = 'red'
        target.style.opacity = '0.5'
        break
      }
      case LOG_STATUS.COOL: {
        target.style.backgroundColor = 'blue'
        target.style.opacity = '0.5'
        break
      }
      case LOG_STATUS.OBSERVING: {
        target.style.backgroundColor = 'green'
        target.style.opacity = '0.5'
        break
      }
      case LOG_STATUS.INIT:
      default:
      //do nothing
    }
  }
  const mutationObserver = new MutationObserver((mutationRecords) => {
    mutationRecords.forEach((mutationRecord) => {
      const observerTarget = mutationRecord.target as HTMLElement
      if (mutationRecord.attributeName !== 'data-log-status') return
      if (!observerTarget.dataset.logStatus) return
      doColor(observerTarget)
    })
  })
  const doVisualise = () => {
    const els: NodeListOf<HTMLElement> =
      document.querySelectorAll('[data-log-status]')
    els.forEach((el) => {
      doColor(el)
    })
    mutationObserver.observe(document.body, {
      attributes: true,
      childList: false,
      characterData: false,
      subtree: true,
    })
  }
  const disconnectVisualise = () => {
    mutationObserver.disconnect()
    const els: NodeListOf<HTMLElement> =
      document.querySelectorAll('[data-log-status]')
    els.forEach((el) => {
      if (el.style.backgroundColor && el.style.opacity) {
        el.style.backgroundColor = ''
        el.style.opacity = ''
      }
    })
  }
  return {
    ...initState,
    visualiseObserver: mutationObserver,
    doVisualise,
    disconnectVisualise,
  }
}

export const dummyLogger = ({ name, params = {} }: LogType) => {
  console.log({
    target: 'DUMMY_LOGGER',
    name: `client_${name}`,
    params: snakecaseKeys({
      ...compactMap(params),
    }),
  })
}

/**
 * Creates an object with all null values removed.
 * @param obj The object to compact
 */
export function compactMap<T extends {}>(
  obj: T
): {
  [key in keyof T]: NonNullable<T[key]>
} {
  return omitBy(obj, (value) => value === null || value === undefined) as any
}
