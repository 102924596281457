import { Placeholder } from '@daangn/search-sdk'
import * as css from './SkeletonAggregatedTabs.css'

const AggregatedTabsSkeleton = () => {
  return (
    <div className={css.container}>
      <div className={css.nav}>
        <div className={css.aggregatedTab}>통합</div>
        <div className={css.navTab}>
          <Placeholder
            width="3rem"
            height="1rem"
            border={{
              radius: '1rem',
            }}
            margin={{
              left: '0.82rem',
              right: '0.92rem',
            }}
          />
        </div>
        <div className={css.navTab}>
          <Placeholder
            width="3rem"
            height="1rem"
            border={{
              radius: '1rem',
            }}
            margin={{
              left: '0.82rem',
              right: '0.92rem',
            }}
          />
        </div>
        <div className={css.navTab}>
          <Placeholder
            width="3rem"
            height="1rem"
            border={{
              radius: '1rem',
            }}
            margin={{
              left: '0.82rem',
              right: '0.92rem',
            }}
          />
        </div>
        <div className={css.navTab}>
          <Placeholder
            width="3rem"
            height="1rem"
            border={{
              radius: '1rem',
            }}
            margin={{
              left: '0.82rem',
              right: '0.92rem',
            }}
          />
        </div>
        <div className={css.navTab}>
          <Placeholder
            width="1.8rem"
            height="1rem"
            border={{
              radius: '1rem',
            }}
            margin={{
              left: '0.6rem',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AggregatedTabsSkeleton
