import { ReactNode, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { INTERNAL_USER_IDS, STAGE } from '../constants/common'
import { useFoundation, UserType } from './FoundationProvider'

interface SupportToolsProviderProps {
  children: ReactNode
}

const useVConsole = ({ user }: { user: UserType }) => {
  useEffect(() => {
    const isInternalProduction =
      STAGE === 'production' && INTERNAL_USER_IDS.includes(user.id.toString())

    const isWebviewDevelopment =
      STAGE === 'development' && !window.location.host.includes('localhost')

    if (isWebviewDevelopment || STAGE === 'alpha' || isInternalProduction) {
      ;(async () => {
        const { default: VConsole } = await import('vconsole')
        new VConsole()
      })()
    }
  }, [user])
}

// const useClarity = () => {
//   useEffectMountOnce(() => {
//     if (STAGE !== 'production') {
//       return
//     }
//
//     const scriptTag = document.createElement('script')
//     scriptTag.type = 'text/javascript'
//     scriptTag.innerHTML = `
//       (function(c,l,a,r,i,t,y){
//         c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
//         t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
//         y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
//       })(window, document, "clarity", "script", "${CLARITY_PROJECT_ID}");
//   `
//
//     const headTag = document.querySelector('head')
//     headTag?.appendChild(scriptTag)
//   })
// }

const useSentryFoundation = () => {
  const { app, user } = useFoundation()
  useEffect(() => {
    if ('production' !== STAGE) {
      return
    }

    Sentry.setUser({
      id: user.id.toString(),
    })
    Sentry.setTags({
      appVersion: app.version,
    })
  }, [app.version, user.id])
}

const SupportToolsProvider = (props: SupportToolsProviderProps) => {
  const { user } = useFoundation()
  useVConsole({ user })
  // T-20850 필요한 시점에 다시 적용
  // useClarity()
  useSentryFoundation()

  return <>{props.children}</>
}

export default SupportToolsProvider
