import {
  atom,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import { useCallback, useMemo } from 'react'
import {
  ExperimentSegmentStatusType,
  EXPERIMENT_SEGMENT_NAME,
} from '../constants/experiment'
import { arrayDeepCopy } from '../../_app/utils/helpers'
import { EXPERIMENT_LIVE_KEYS } from '../index'

type LiveExperimentValueTypes =
  (typeof EXPERIMENT_LIVE_KEYS)[keyof typeof EXPERIMENT_LIVE_KEYS]

export interface ExperimentParams {
  key: LiveExperimentValueTypes
}
export interface ExperimentSegmentType {
  segmentKey: string
  segmentName: string
  segmentType?: ExperimentSegmentStatusType
}

interface experimentStateType {
  experimentSegments: ExperimentSegmentType[]
  headerSegmentKey: string
  headerSegmentValue: string
}

export interface ExperimentType {
  experimentState: experimentStateType
}

export const experimentState = atom<ExperimentType>({
  key: 'app/experiment',
  default: {
    experimentState: {
      experimentSegments: [],
      headerSegmentKey: '',
      headerSegmentValue: '',
    },
  },
})

export const useExperimentState = () => {
  return useRecoilValue(experimentState)
}

export const useExperimentSegment = () => {
  const state = useExperimentState()

  const experimentSegmentObject = useMemo(() => {
    const experimentSegments = state.experimentState.experimentSegments
    return experimentSegments.reduce<any>((acc, cur) => {
      acc[cur.segmentKey] = cur.segmentName
      return acc
    }, {})
  }, [state.experimentState.experimentSegments])

  const searchExperimentSegmentArray = useMemo(() => {
    return state.experimentState.experimentSegments
  }, [state.experimentState.experimentSegments])

  const experimentHeaderSegmentReferrer = useMemo(() => {
    return {
      headerSegmentKey: state.experimentState.headerSegmentKey ?? '',
      headerSegmentValue: state.experimentState.headerSegmentValue ?? '',
    }
  }, [
    state.experimentState.headerSegmentKey,
    state.experimentState.headerSegmentValue,
  ])

  return useMemo(
    () => ({
      experimentSegmentObject: experimentSegmentObject,
      searchExperimentSegmentArray: searchExperimentSegmentArray,
      experimentHeaderSegmentReferrer: experimentHeaderSegmentReferrer,
    }),
    [
      experimentSegmentObject,
      searchExperimentSegmentArray,
      experimentHeaderSegmentReferrer,
    ]
  )
}

export const useExperimentActions = () => {
  const setter = useSetRecoilState(experimentState)
  const resetter = useResetRecoilState(experimentState)

  const setExperimentState = useCallback(
    ({ experimentState }: ExperimentType) => {
      const { experimentSegments, headerSegmentKey, headerSegmentValue } =
        experimentState
      const deepCopiedExperimentSegments = arrayDeepCopy(experimentSegments)

      setter({
        experimentState: {
          experimentSegments: deepCopiedExperimentSegments,
          headerSegmentKey: headerSegmentKey,
          headerSegmentValue: headerSegmentValue,
        },
      })
    },
    [setter]
  )

  return useMemo(() => {
    return {
      init: () => resetter(),
      setExperimentState,
    }
  }, [resetter, setExperimentState])
}

export const useExperimentSegmentTreatment = ({ key }: ExperimentParams) => {
  const { searchExperimentSegmentArray } = useExperimentSegment()
  const getExperimentSegment = useCallback(() => {
    return searchExperimentSegmentArray.find(
      ({ segmentKey }) => segmentKey === key
    )
  }, [searchExperimentSegmentArray, key])

  const isExperimentControl = useMemo(() => {
    const segment = getExperimentSegment()
    if (!segment) {
      return false
    }

    return segment.segmentName === EXPERIMENT_SEGMENT_NAME.CONTROL
  }, [getExperimentSegment])

  const isExperimentTreatment1 = useMemo(() => {
    const segment = getExperimentSegment()
    if (!segment) {
      return false
    }

    return segment.segmentName === EXPERIMENT_SEGMENT_NAME.TREATMENT_1
  }, [getExperimentSegment])

  const isExperimentTreatment2 = useMemo(() => {
    const segment = getExperimentSegment()
    if (!segment) {
      return false
    }

    return segment.segmentName === EXPERIMENT_SEGMENT_NAME.TREATMENT_2
  }, [getExperimentSegment])

  const isExperimentTreatment3 = useMemo(() => {
    const segment = getExperimentSegment()
    if (!segment) {
      return false
    }

    return segment.segmentName === EXPERIMENT_SEGMENT_NAME.TREATMENT_3
  }, [getExperimentSegment])

  const isExperimentTreatment4 = useMemo(() => {
    const segment = getExperimentSegment()
    if (!segment) {
      return false
    }

    return segment.segmentName === EXPERIMENT_SEGMENT_NAME.TREATMENT_4
  }, [getExperimentSegment])

  const isExperimentTreatment5 = useMemo(() => {
    const segment = getExperimentSegment()
    if (!segment) {
      return false
    }

    return segment.segmentName === EXPERIMENT_SEGMENT_NAME.TREATMENT_5
  }, [getExperimentSegment])

  return useMemo(
    () => ({
      isExperimentControl,
      isExperimentTreatment1,
      isExperimentTreatment2,
      isExperimentTreatment3,
      isExperimentTreatment4,
      isExperimentTreatment5,
    }),
    [
      isExperimentControl,
      isExperimentTreatment1,
      isExperimentTreatment2,
      isExperimentTreatment3,
      isExperimentTreatment4,
      isExperimentTreatment5,
    ]
  )
}
