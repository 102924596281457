import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useRef,
  RefObject,
} from 'react'
import { v4 } from 'uuid'
import { setReferrerBridgePersistedState } from '../../_app/stores/PersistentStore'

type State = {
  searchFunnelId: string
  isMounted: RefObject<boolean>
}

const SearchFunnelIdStateContext = createContext<State | null>(null)
const SearchFunnelIdUpdaterContext = createContext<
  ((action: 'update' | 'restore', newFunnelId: string) => void) | null
>(null)

interface SearchFunnelIdProviderProps {
  children: ReactNode
}

export const SearchFunnelIdProvider = (props: SearchFunnelIdProviderProps) => {
  const [funnelId, setFunnelId] = useState<string>(v4())
  const isMounted = useRef<boolean>(false)

  const handleUpdater = (action: 'update' | 'restore', newFunnelId: string) => {
    isMounted.current = true

    if (!newFunnelId) {
      return
    }

    setFunnelId(newFunnelId)
    setReferrerBridgePersistedState({
      searchFunnelId: action === 'update' ? newFunnelId : '',
    })
  }

  return (
    <SearchFunnelIdStateContext.Provider
      value={{ searchFunnelId: funnelId, isMounted: isMounted }}
    >
      <SearchFunnelIdUpdaterContext.Provider value={handleUpdater}>
        {props.children}
      </SearchFunnelIdUpdaterContext.Provider>
    </SearchFunnelIdStateContext.Provider>
  )
}

export function useSearchFunnelId() {
  const state = useContext(SearchFunnelIdStateContext)
  const updater = useContext(SearchFunnelIdUpdaterContext)

  if (!state) {
    throw new Error('not found SearchFunnelIdStateContext')
  }

  if (!updater) {
    throw new Error('not found SearchFunnelIdUpdaterContext')
  }

  return {
    searchFunnelId: state.searchFunnelId,
    isMountedRef: state.isMounted,
    updateSearchFunnelId: updater,
  }
}
