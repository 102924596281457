import { BADGE_STYLE_TYPE } from '@daangn/search-sdk'
export const CAR_STATUS = {
  NONE: 'NONE',
  /**
   * 판매중
   */
  SALES_STATUS: 'SALES_STATUS',
  /**
   * 예약중
   */
  RESERVE: 'RESERVE',
  /**
   * 거래완료
   */
  TRADED: 'TRADED',
} as const

export type CarStatusType = (typeof CAR_STATUS)[keyof typeof CAR_STATUS]

export const CAR_OPTION_NAME = {
  NOT_SPECIFIED: 'NOT_SPECIFIED',
  /**
   * 금연 차량
   */
  SMOKING_FREE: 'SMOKING_FREE',
} as const

export type CarOptionNameType =
  (typeof CAR_OPTION_NAME)[keyof typeof CAR_OPTION_NAME]

export const CAR_HISTORY_CHANGE_TYPE = {
  /**
   * 최초 등록
   */
  REGISTER: 'REGISTER',
  /**
   * 차량 번호 변경
   */
  CAR_NO: 'CAR_NO',

  /**
   * 소유자 변경
   */
  OWNER: 'OWNER',
  /**
   * unknown
   */
  UNKNOWN: 'UNKNOWN',
} as const

export type CarHistoryChangeType =
  (typeof CAR_HISTORY_CHANGE_TYPE)[keyof typeof CAR_HISTORY_CHANGE_TYPE]

export const BADGE_TYPE = {
  ONLY_ONE_OWNER: {
    style: BADGE_STYLE_TYPE.DEFAULT,
    label: '1인 소유',
  },
  SMOKING_FREE: {
    style: BADGE_STYLE_TYPE.DEFAULT,
    label: '금연 차량',
  },
  TRADED: {
    style: BADGE_STYLE_TYPE.TRADED,
    label: '거래완료',
  },
  RESERVE: {
    style: BADGE_STYLE_TYPE.RESERVE,
    label: '예약중',
  },
}
