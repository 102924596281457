import { MouseEvent } from 'react'
import IconClock from './assets/IconClock'
import IconClose from './assets/IconClose'
import IconContainerRecentSearch from './IconContainerRecentSearch'
import * as css from './RecentKeyword.css'

interface RecentKeywordProps {
  keyword: string
  eventTime?: string
  index: number
  onClick: (e: MouseEvent<HTMLLIElement>) => void
  onRemoveClick: (e: MouseEvent) => void
}

const RecentKeyword = (props: RecentKeywordProps) => {
  return (
    <li
      className={`search__recent-keyword ${css.root}`}
      data-recent-keyword-event-time={props.eventTime}
      onClick={props.onClick}
    >
      <div className={css.keywordContainer}>
        <div className={css.keywordWithIconContainer}>
          <IconContainerRecentSearch>
            <IconClock />
          </IconContainerRecentSearch>
          <div className={css.keyword} role="button">
            {props.keyword}
          </div>
        </div>
        <button
          className={css.removeIconButton}
          aria-label="삭제"
          onClick={props.onRemoveClick}
        >
          <IconClose />
        </button>
      </div>
    </li>
  )
}
export default RecentKeyword
