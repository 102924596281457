export const BADGE_STYLE_TYPE = {
  DEFAULT: 'default',
  TRADED: 'traded',
  RESERVE: 'reserve',
} as const

export type BadgeStyleType =
  (typeof BADGE_STYLE_TYPE)[keyof typeof BADGE_STYLE_TYPE]

export const BADGE_STYLE_TYPE_TO_PROPS = {
  [BADGE_STYLE_TYPE.DEFAULT]: {
    variant: 'basic',
    type: 'normal',
  },
  [BADGE_STYLE_TYPE.TRADED]: {
    variant: 'basic',
    type: 'bold',
  },
  [BADGE_STYLE_TYPE.RESERVE]: {
    variant: 'success',
    type: 'bold',
  },
} as const
