import { memo, useMemo } from 'react'
import * as css from './CardPlace.css'
import { commaNumber } from '../_app'
import { LazyImage } from '../lazy-load-image'
import { TagGroup } from '../tag-group'
import { Tag } from '../tag'
import { Badge } from '../badge'

interface CardPlaceProps {
  title: string
  regionName: string
  thumbnail?: string
  fallbackThumbnail?: string
  content?: string
  reviews?: number
  followers?: number
  categoryName?: string
  showPurchase?: boolean
  reservable?: boolean
  hasCoupon?: boolean
  isNew: boolean
  distance?: number
}

const CardPlace = (props: CardPlaceProps) => {
  const showReviews = useMemo(() => {
    return !!(props.reviews && props.reviews > 0)
  }, [props.reviews])

  const showFollowers = useMemo(() => {
    return !!(props.followers && props.followers > 0)
  }, [props.followers])

  const commaReviewCount = useMemo(() => {
    return props.reviews ? commaNumber(props.reviews) : 0
  }, [props.reviews])

  const commaFollowerCount = useMemo(() => {
    return props.followers ? commaNumber(props.followers) : 0
  }, [props.followers])

  const a11yLabel = useMemo(() => {
    return `동네업체 ${props.title} ${props.regionName} ${
      props.content ? `${props.content} ` : ''
    }후기${showReviews ? props.reviews : 0} 단골${
      showFollowers ? props.followers : 0
    } ${props.categoryName ? `${props.categoryName} ` : ''}${
      props.reservable ? '바로예약 ' : ''
    }${props.showPurchase ? '바로결제 ' : ''}${props.hasCoupon ? '쿠폰 ' : ''}${
      props.distance ? displayDistance(props.distance) : ''
    }`
  }, [
    props.title,
    props.regionName,
    props.content,
    showReviews,
    props.reviews,
    showFollowers,
    props.followers,
    props.categoryName,
    props.showPurchase,
    props.hasCoupon,
    props.reservable,
    props.distance,
  ])

  return (
    <div className={css.button} aria-label={a11yLabel}>
      <div className={css.contents}>
        <div className={css.titleContainer}>
          <span
            className={css.title({
              isNew: props.isNew,
            })}
          >
            {props.title}
          </span>
          {props.isNew ? (
            <span className={css.badgeNew}>
              <BadgeNew />
            </span>
          ) : null}
          <span className={css.categoryName}>{props.categoryName}</span>
        </div>
        <div className={css.body}>
          {props.content ? (
            <div className={css.bodyContents}>{props.content}</div>
          ) : null}
          <TagArea
            regionName={props.regionName}
            reviewCount={commaReviewCount}
            showFollowers={showFollowers}
            showReviews={showReviews}
            followerCount={commaFollowerCount}
            distance={props.distance}
          />
          <Badges
            reservable={props.reservable}
            showPurchase={props.showPurchase}
            hasCoupon={props.hasCoupon}
          />
        </div>
      </div>
      {props.thumbnail ? (
        <div className={css.imageContainer}>
          <LazyImage className={css.image} src={props.thumbnail} />
        </div>
      ) : null}
    </div>
  )
}

interface TagAreaProps {
  regionName: string
  showReviews: boolean
  showFollowers: boolean
  reviewCount: number | string
  followerCount: number | string
  distance?: number
}

const TagArea = (props: TagAreaProps) => {
  return (
    <div className={css.reactionContainer}>
      {props.showReviews || props.showFollowers ? (
        <div className={css.reviewAndFollowerTagGroup}>
          <TagGroup medium>
            {props.showReviews ? (
              <Tag large>후기 {props.reviewCount}</Tag>
            ) : null}
            {props.showFollowers ? (
              <Tag large>단골 {props.followerCount}</Tag>
            ) : null}
          </TagGroup>
        </div>
      ) : null}
      {props.distance || props.regionName ? (
        <TagGroup medium>
          {props.distance ? (
            <Tag large>{displayDistance(props.distance)}</Tag>
          ) : null}
          {props.regionName ? <Tag large>{props.regionName}</Tag> : null}
        </TagGroup>
      ) : null}
    </div>
  )
}

interface BadgesProps {
  reservable?: boolean
  showPurchase?: boolean
  hasCoupon?: boolean
}

const Badges = (props: BadgesProps) => {
  if (!props.reservable && !props.showPurchase && !props.hasCoupon) {
    return null
  }

  return (
    <div className={css.badgeContainer}>
      {props.reservable ? (
        <Badge
          className={css.badge}
          variant="accent"
          size="medium"
          type="normal"
          shape="square"
        >
          바로예약
        </Badge>
      ) : null}
      {props.showPurchase ? (
        <Badge
          className={css.badge}
          variant="basic"
          size="medium"
          type="normal"
          shape="square"
        >
          바로결제
        </Badge>
      ) : null}
      {props.hasCoupon ? (
        <Badge
          className={css.badge}
          variant="basic"
          size="medium"
          type="normal"
          shape="square"
        >
          쿠폰
        </Badge>
      ) : null}
    </div>
  )
}

const BadgeNew = memo(() => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00016 13.4166C10.544 13.4166 13.4168 10.5437 13.4168 6.99992C13.4168 3.45609 10.544 0.583252 7.00016 0.583252C3.45634 0.583252 0.583496 3.45609 0.583496 6.99992C0.583496 10.5437 3.45634 13.4166 7.00016 13.4166ZM9.91683 4.91658C9.91683 4.45635 9.54373 4.08325 9.0835 4.08325C8.62326 4.08325 8.25016 4.45635 8.25016 4.91658V7.17223L5.48387 4.60354C5.24099 4.37801 4.88755 4.31785 4.58373 4.45034C4.27992 4.58282 4.0835 4.88276 4.0835 5.2142V9.08324C4.0835 9.54347 4.45659 9.91657 4.91683 9.91657C5.37707 9.91657 5.75016 9.54347 5.75016 9.08324V7.12521L8.51645 9.6939C8.75933 9.91943 9.11278 9.97958 9.41659 9.8471C9.72041 9.71462 9.91683 9.41468 9.91683 9.08324V4.91658Z"
        fill="#FF6F0F"
      />
    </svg>
  )
})

const displayDistance = (distance?: number) => {
  if (!distance) {
    return ''
  }

  const isDistanceInMeter = distance / 1000 < 1

  if (isDistanceInMeter) {
    if (distance < 10) {
      return `${Math.floor(distance)}m` // 10m 미만 정수로만 표시
    }

    return `${Math.floor(distance / 10) * 10}m` // 10m 단위로 표시
  }

  return `${Math.floor(distance / 100) / 10}km` // 소수점 버림, km는 소수점 첫째 자리까지 표시
}

export default CardPlace
