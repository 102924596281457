import { ReactNode } from 'react'
import { MutableSnapshot, RecoilRoot } from 'recoil'
import { useExperimentContext } from './AppInitializeProvider'
import { experimentState as store } from '../../experiment/stores/ExperimentStore'

interface GlobalMutableStateProviderProps {
  children: ReactNode
}

const GlobalMutableStateProvider = (props: GlobalMutableStateProviderProps) => {
  const { experimentState } = useExperimentContext()

  const injectInitialExperimentState = ({ set }: MutableSnapshot) => {
    set(store, {
      experimentState,
    })
  }

  return (
    <RecoilRoot initializeState={injectInitialExperimentState}>
      {props.children}
    </RecoilRoot>
  )
}

export default GlobalMutableStateProvider
