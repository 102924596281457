import { Environment, Network, RecordSource, Store } from 'relay-runtime'
import { RelayEnvironmentProvider } from 'react-relay'

import { createContext } from '../_app/graphql/context'
import { createSchema } from '../_app/graphql/schema'
import { ReactNode, useMemo } from 'react'
import { AppType, useFoundation } from '../_app/context/FoundationProvider'
import { SearchExperimentReferrerType } from '../referrer/types'
import { useExperimentSegment } from '../experiment/stores/ExperimentStore'

const source = new RecordSource()
const store = new Store(source)

export function createEnvironment({
  app,
  authToken,
  regionId,
  experiment,
}: {
  app: AppType
  authToken: string
  regionId?: number
  experiment: SearchExperimentReferrerType
}) {
  const { query } = createSchema()

  const context = createContext({
    app,
    authToken,
    regionId,
    experiment,
  })

  return new Environment({
    network: Network.create((operation, variables) => {
      return query({
        query: operation.text!,
        variables,
        context,
      })
    }),
    store,
  })
}

export const RelayEnvironmentWithContextProvider = (props: {
  children: ReactNode
}) => {
  const { app, user, region } = useFoundation()
  const { experimentHeaderSegmentReferrer } = useExperimentSegment()

  const memoriesEnvironment = useMemo(
    () =>
      createEnvironment({
        app: app,
        authToken: user.authToken,
        regionId: region?.id,
        experiment: experimentHeaderSegmentReferrer,
      }),
    [app, region?.id, user.authToken, experimentHeaderSegmentReferrer]
  )

  return (
    <RelayEnvironmentProvider environment={memoriesEnvironment}>
      {props.children}
    </RelayEnvironmentProvider>
  )
}
