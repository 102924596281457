import { LogStatusType } from './types'

export const IMPRESSION_COOL_TIME = 60000
export const IMPRESSION_EXPOSURE_TIME = 1000
export const IMPRESSION_THRESHOLD = 0.5

export const LOG_STATUS = {
  INIT: 'init' as LogStatusType,
  OBSERVING: 'observing' as LogStatusType,
  OBSERVED: 'observed' as LogStatusType,
  DISABLE: 'disable' as LogStatusType,
  COOL: 'cool' as LogStatusType,
  DISCONNECT: '' as LogStatusType,
} as const
