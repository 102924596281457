import * as Sentry from '@sentry/react'
import axios, { AxiosError } from 'axios'
import { SearchResponseType } from '../../services/common'

export function captureException(error?: Error | null, errorInfo?: any) {
  if (error?.message === 'Network Error') {
    return
  }
  if (error?.message === 'Network request failed') {
    return
  }
  if (error) {
    Sentry.captureException(error, { extra: errorInfo })
  }
}

export function captureAxiosError<T extends SearchResponseType<any>>(
  error: any,
  options?: {
    excludeErrors: number[]
  }
): {
  status: number
  data: T
} | null {
  // 네트워크 에러면 sentry에 안보냄
  if (
    error?.message === 'Network Error' ||
    error?.message === 'Network request failed'
  ) {
    return null
  }

  if (error && axios.isAxiosError(error)) {
    const serverError = error as AxiosError<T>

    if (serverError && serverError.response) {
      const sendError = !options?.excludeErrors.includes(
        serverError.response?.status
      )

      if (sendError) {
        Sentry.captureException(serverError)
      }

      return {
        status: serverError.response.status,
        data: serverError.response.data,
      }
    }
  }

  // 파악할 수 없는 에러면 보냄
  if (error) {
    Sentry.captureException(error)
  }

  return null
}
