import { ReactNode, Suspense } from 'react'
import SearchDiscoverSkeleton from '../search-discover/SearchDiscoverSkeleton'
import PageSearchResultSkeleton from '../../../_pages/PageSearchResultSkeleton'
import {
  executeByEnvironment,
  karrotBridge,
} from '../../../bridge/utils/bridgeMaker'

interface EntryPointSkeletonProps {
  children: ReactNode
}

const EntryPointSkeleton = (props: EntryPointSkeletonProps) => {
  if (window.location.pathname.startsWith('/search/discover')) {
    return (
      <Suspense fallback={<SearchDiscoverSkeleton />}>
        {props.children}
      </Suspense>
    )
  }

  const params = new URLSearchParams(document.location.search)
  const queryValue = params.get('query')

  if (window.location.pathname.startsWith('/search') && queryValue) {
    return (
      <Suspense
        fallback={
          <PageSearchResultSkeleton
            query={queryValue}
            onBackClick={() => {
              executeByEnvironment({
                onApp: () => karrotBridge.closeRouter({}),
                onWeb: () => window.history.back(),
              })
            }}
          />
        }
      >
        {props.children}
      </Suspense>
    )
  }

  return <Suspense fallback={null}>{props.children}</Suspense>
}

export default EntryPointSkeleton
