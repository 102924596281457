import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'

import {
  Configuration,
  RecentSearchServiceApiFactory,
} from '../../__codegen__/__openapi__/search-server/recentsearch/v4/recentsearch'

import { RETRY_COUNT, SEARCH_ENDPOINT } from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import { AppType } from '../../_app/context/FoundationProvider'

import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import {
  collectionWithError,
  getPreDefinedServiceError,
} from '../../error/utils/getPreDefinedServiceError'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import { SearchReferrerType } from '../../referrer/types'

interface RecentSearchReferrerType {
  referrer: Pick<SearchReferrerType, 'experiment'>
}

interface RecentSearchDeleteAllRequest extends RecentSearchReferrerType {}

interface RecentSearchDeleteKeywordRequest extends RecentSearchReferrerType {
  query: string
}

interface RecentSearchDeleteShortcutRequest extends RecentSearchReferrerType {
  shortcutId: string
}

interface RecentSearchGetAllKeywordsRequest extends RecentSearchReferrerType {
  regionId: string
}

let serviceCache: ServiceRecentSearchType | null = null
export type ServiceRecentSearchType = ReturnType<typeof ServiceRecentSearch>

export const getServiceRecentSearch = ({
  authToken,
  app,
}: {
  authToken: string
  app: AppType
}) => {
  if (serviceCache) {
    return serviceCache
  }
  return (serviceCache = ServiceRecentSearch({
    baseUrl: SEARCH_ENDPOINT,
    authToken,
    app,
  }))
}

const ServiceRecentSearch = ({
  baseUrl,
  authToken,
  app,
}: {
  baseUrl: string
  authToken: string
  app: AppType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: [
      plantaeAuthPlugin({ fallbackAuthToken: authToken }),
      plantaeRequestIdPlugin(),
      plantaeKarrotSessionIdPlugin({ app }),
      plantaeUserAgentPlugin({ userAgent: app.userAgent }),
      plantaeCommonHeadersPlugin(),
      plantaeSearchOriginPlugin(),
    ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
  })

  const client = RecentSearchServiceApiFactory(
    new Configuration({
      apiKey: authToken,
    }),
    baseUrl,
    axiosInstance
  )
  return {
    deleteAllRecentQuery: async ({
      referrer,
    }: RecentSearchDeleteAllRequest) => {
      const [error, resp] = await to(
        client.recentSearchServiceBatchDelete(
          {},
          undefined, // xSearchUserId,
          undefined, // xRequestId,
          undefined, // xKarrotSessionId,
          referrer.experiment.headerSegmentValue ?? '', // xSearchTestGroup,
          undefined, // xSearchFunnelId,
          undefined, // xSearchFunnelFrom,
          undefined, // xSearchQueryFrom,
          undefined, // xSearchTab,
          undefined, // xSearchScreenDepthName,
          undefined, // xUserAgent,
          undefined // xForwardedFor
        )
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      const preDefinedServiceError = getPreDefinedServiceError(
        resp.data as collectionWithError
      )

      return {
        result: resp.data != null && !preDefinedServiceError ? 'success' : null,
        ...(preDefinedServiceError && { error: preDefinedServiceError }),
      }
    },
    deleteRecentKeyword: async ({
      query,
      referrer,
    }: RecentSearchDeleteKeywordRequest) => {
      const [error, resp] = await to(
        client.recentSearchServiceDeleteQuery(
          { query },
          undefined, // xSearchUserId,
          undefined, // xRequestId,
          undefined, // xKarrotSessionId,
          referrer.experiment.headerSegmentValue ?? '', // xSearchTestGroup,
          undefined, // xSearchFunnelId,
          undefined, // xSearchFunnelFrom,
          undefined, // xSearchQueryFrom,
          undefined, // xSearchTab,
          undefined, // xSearchScreenDepthName,
          undefined, // xUserAgent,
          undefined // xForwardedFor
        )
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      const preDefinedServiceError = getPreDefinedServiceError(
        resp.data as collectionWithError
      )

      return {
        result: resp.data != null && !preDefinedServiceError ? 'success' : null,
        ...(preDefinedServiceError && { error: preDefinedServiceError }),
      }
    },
    deleteRecentShortcut: async ({
      shortcutId,
      referrer,
    }: RecentSearchDeleteShortcutRequest) => {
      const [error, resp] = await to(
        client.recentSearchServiceDeleteShortcut(
          {
            shortcutId,
          },
          undefined, // xSearchUserId,
          undefined, // xRequestId,
          undefined, // xKarrotSessionId,
          referrer.experiment.headerSegmentValue ?? '', // xSearchTestGroup,
          undefined, // xSearchFunnelId,
          undefined, // xSearchFunnelFrom,
          undefined, // xSearchQueryFrom,
          undefined, // xSearchTab,
          undefined, // xSearchScreenDepthName,
          undefined, // xUserAgent,
          undefined // xForwardedFor
        )
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      const preDefinedServiceError = getPreDefinedServiceError(
        resp.data as collectionWithError
      )

      return {
        result: resp.data != null && !preDefinedServiceError ? 'success' : null,
        ...(preDefinedServiceError && { error: preDefinedServiceError }),
      }
    },
    getRecentSearchQueries: async ({
      regionId,
      referrer,
    }: RecentSearchGetAllKeywordsRequest) => {
      const [error, resp] = await to(
        client.recentSearchServiceList(
          regionId,
          undefined, // xSearchUserId,
          undefined, // xRequestId,
          undefined, // xKarrotSessionId,
          referrer.experiment.headerSegmentValue ?? '', // xSearchTestGroup,
          undefined, // xSearchFunnelId,
          undefined, // xSearchFunnelFrom,
          undefined, // xSearchQueryFrom,
          undefined, // xSearchTab,
          undefined, // xSearchScreenDepthName,
          undefined, // xUserAgent,
          undefined // xForwardedFor
        )
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      const preDefinedServiceError = getPreDefinedServiceError(
        resp.data as collectionWithError
      )

      return {
        queries: resp.data.queries || [],
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          resp.headers
        ),
        ...(preDefinedServiceError && { error: preDefinedServiceError }),
      }
    },
  }
}
